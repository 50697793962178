            import React, { useEffect, useState } from 'react'
            import { Link } from 'react-router-dom'
            import Allpopups from '../data/Allpopups'
            import { getAdvancedPanchang, getAdvancedPanchangSunrise, getBasicPanchang, getBasicPanchangSunrise, getChaughadiyaMuhurta, getPlanetPanchang, getPlanetPanchangSunrise } from '../data/AllapiData';
            import Header from '../components/Header';
            import { Domain_Url } from '../components/Url/Url';
            import '../CssFiles/Panchang.css';


            const Panchang = () => {
            const [basicPanchang, setBasicPanchang] = useState(null);
            const [basicPanchangSunrise, setBasicPanchangSunrise] = useState(null);
            const [advancedPanchang, setAdvancedPanchang] = useState(null);
            const [advancedPanchangSunrise, setAdvancedPanchangSunrise] = useState(null);
            console.warn(advancedPanchangSunrise)
            const [planetPanchang, setPlanetPanchang] = useState(null);
            const [planetPanchangSunrise, setPlanetPanchangSunrise] = useState(null);
            const [submitAfPanchangData,setSubmitAfPanchangData]=useState(null);
            const [allChaughadiyaTimings,setAllChaughadiyaTimings] =useState()
            const [chaughadiyaMuhurta, setChaughadiyaMuhurta] = useState(null);
            const [currentMuhurta, setCurrentMuhurta] = useState(null);
            const [currentTime, setCurrentTime] = useState('');
            console.warn('Basic Panchang:', basicPanchang);

            console.warn('Basic Panchang Sunrise:', basicPanchangSunrise);
            console.warn('Advanced Panchang:', advancedPanchang);
            console.warn('Advanced Panchang Sunrise:', advancedPanchangSunrise);
            console.warn('Planet Panchang:', planetPanchang);
            console.warn('Planet Panchang Sunrise:', planetPanchangSunrise);
            console.warn('All Timings:', allChaughadiyaTimings);
            console.warn('Chaughadiya Muhurta:', chaughadiyaMuhurta);
            console.warn('Current Muhurta:', currentMuhurta);
            console.warn('Current Time:', currentTime);
            const [openTodayPanchang, setOpenTodayPanchang] = useState(false);

            const toggleTodayPanchangOpen = () => {
              setOpenTodayPanchang(!openTodayPanchang);
            };
            useEffect(() => {
            if (chaughadiyaMuhurta) {
            // Combine day and night timings
            const combinedTimings = [...chaughadiyaMuhurta.day, ...chaughadiyaMuhurta.night];
            const combineHoraTimings = [...chaughadiyaMuhurta.day, ...chaughadiyaMuhurta.night];
            setAllChaughadiyaTimings(combinedTimings);

            // Filter based on current time
            const currentMuhurta = getCurrentMuhurta(combinedTimings);
            const currentTime = getCurrentTime(combinedTimings);
            setCurrentTime(currentTime);
            setCurrentMuhurta(currentMuhurta);

            }
            }, [chaughadiyaMuhurta]);
            const getCurrentMuhurta = (timings) => {
            const now = new Date();

            // Helper function to parse time strings into Date objects
            const parseTime = (timeStr) => {
            const [hours, minutes, seconds] = timeStr.split(':').map(Number);
            return new Date().setHours(hours, minutes, seconds, 0);
            };

            // Find the appropriate timing interval
            for (const interval of timings) {
            const [startTime, endTime] = interval.time.split(' - ').map(parseTime);
            if (now >= startTime && now < endTime) {
            return interval.muhurta;
            }
            }
            return 'No current muhurta'; // Default message if no matching muhurta is found
            };
            const getCurrentTime = (timings) => {
            const now = new Date();

            // Helper function to parse time strings into Date objects
            const parseTime = (timeStr) => {
            const [hours, minutes, seconds] = timeStr.split(':').map(Number);
            return new Date().setHours(hours, minutes, seconds, 0);
            };

            // Find the appropriate timing interval
            for (const interval of timings) {
            const [startTime, endTime] = interval.time.split(' - ').map(parseTime);
            if (now >= startTime && now < endTime) {
            return interval.time;
            }
            }
            return 'No current muhurta'; // Default message if no matching muhurta is found
            };


            const [panchangLagnaTable, setPanchangLagnaTable] = useState(null);
            console.error(panchangLagnaTable)
            const [monthlyPanchang, setMonthlyPanchang] = useState(null);

            const timezone = new Date().getTimezoneOffset() / -60;
            const [dataSend, setDataSend] = useState(null);
            const [panchangData, setPanchangData] = useState({
            day: new Date().getDate(),
            month: new Date().getMonth() + 1, // getMonth() returns 0-based month
            year: new Date().getFullYear(),
            });

            function changePanchangData(e) {
            setPanchangData({
            ...panchangData,
            [e.target.name]: e.target.value,
            });
            }

            const hour = 15;
            const min = 30;
            const lat = 25.7464;
            const lon = 82.6837;
            const tzone = 5.5;
            function getTodayPanchang(e){
            window.location.reload()
            }
            async function submitPanchangData(event) {
            event?.preventDefault(); // Prevent the default form submission

            try {
            // Set the initial panchang data
            setSubmitAfPanchangData(panchangData);

            // Fetch and set basic panchang data
            const basicPanchangData = await getBasicPanchang(panchangData);
            setBasicPanchang(basicPanchangData);
            console.log('Basic Panchang:', basicPanchangData);

            // Fetch and set basic panchang sunrise data
            const basicPanchangSunriseData = await getBasicPanchangSunrise(panchangData);
            setBasicPanchangSunrise(basicPanchangSunriseData);
            console.log('Basic Panchang Sunrise:', basicPanchangSunriseData);

            // Fetch and set advanced panchang data
            const advancedPanchangData = await getAdvancedPanchang(panchangData);
            setAdvancedPanchang(advancedPanchangData);
            console.log('Advanced Panchang:', advancedPanchangData);

            // Fetch and set advanced panchang sunrise data
            const advancedPanchangSunriseData = await getAdvancedPanchangSunrise(panchangData);
            setAdvancedPanchangSunrise(advancedPanchangSunriseData);
            console.log('Advanced Panchang Sunrise:', advancedPanchangSunriseData);

            // Fetch and set planet panchang data
            const planetPanchangData = await getPlanetPanchang(panchangData);
            setPlanetPanchang(planetPanchangData);
            console.log('Planet Panchang:', planetPanchangData);

            // Fetch and set planet panchang sunrise data
            const planetPanchangSunriseData = await getPlanetPanchangSunrise(panchangData);
            setPlanetPanchangSunrise(planetPanchangSunriseData);
            console.log('Planet Panchang Sunrise:', planetPanchangSunriseData);

            // Fetch and set chaughadiya muhurta data
            const chaughadiyaMuhurtaData = await getChaughadiyaMuhurta(panchangData);
            setChaughadiyaMuhurta(chaughadiyaMuhurtaData?.chaughadiya);
            console.log('Chaughadiya Muhurta:', chaughadiyaMuhurtaData);

            // Fetch and set panchang lagna table data
            const panchangLagnaTableData = await panchangLagnaTable(panchangData);
            setPanchangLagnaTable(panchangLagnaTableData);
            console.log('Panchang Lagna Table:', panchangLagnaTableData);

            // Fetch and set monthly panchang data
            const monthlyPanchangData = await monthlyPanchang(panchangData);
            setMonthlyPanchang(monthlyPanchangData);
            console.log('Monthly Panchang:', monthlyPanchangData);

            } catch (error) {
            // Handle any errors that occurred during the fetch operations
            console.error('Error occurred while submitting Panchang data:', error);
            }
            }

            useEffect(() => {
            const today = new Date();
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // Check if Geolocation API is available
            if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
            (position) => {
            const { latitude, longitude } = position.coords;
            const now = new Date();
            setDataSend({

            hour : now.getHours(),
            minute : now.getMinutes(),
            lat: latitude,
            lon: longitude,
            tzone: timezone,
            day: today.getDate(),
            month: today.getMonth() + 1,
            year: today.getFullYear(),
            });
            },
            (error) => {
            console.error("Error getting location: ", error);
            // Fallback to default location if geolocation fails
            setDataSend({
            hour: 0,
            min: 0,
            lat: 0, // Default latitude
            lon: 0, // Default longitude
            tzone: timezone,
            day: today.getDate(),
            month: today.getMonth() + 1,
            year: today.getFullYear(),
            });
            }
            );
            } else {
            console.warn("Geolocation is not supported by this browser.");
            // Fallback to default location if geolocation is not supported
            setDataSend({
            hour: 0,
            min: 0,
            lat: 0, // Default latitude
            lon: 0, // Default longitude
            tzone: timezone,
            day: today.getDate(),
            month: today.getMonth() + 1,
            year: today.getFullYear(),
            });
            }
            }, [submitAfPanchangData]);
            useEffect(() => {
            const fetchAllData = async () => {
            if (!dataSend) return; // Prevent API calls if dataSend is null

            try {
            // Fetch all APIs sequentially
            const basicPanchangData = await getBasicPanchang(dataSend);
            setBasicPanchang(basicPanchangData);
            console.log('Basic Panchang:', basicPanchangData);

            const basicPanchangSunriseData = await getBasicPanchangSunrise(dataSend);
            setBasicPanchangSunrise(basicPanchangSunriseData);
            console.log('Basic Panchang Sunrise:', basicPanchangSunriseData);

            const advancedPanchangData = await getAdvancedPanchang(dataSend);
            setAdvancedPanchang(advancedPanchangData);
            console.log('Advanced Panchang:', advancedPanchangData);

            const advancedPanchangSunriseData = await getAdvancedPanchangSunrise(dataSend);
            setAdvancedPanchangSunrise(advancedPanchangSunriseData);
            console.log('Advanced Panchang Sunrise:', advancedPanchangSunriseData);

            const planetPanchangData = await getPlanetPanchang(dataSend);
            setPlanetPanchang(planetPanchangData);
            console.log('Planet Panchang:', planetPanchangData);

            const planetPanchangSunriseData = await getPlanetPanchangSunrise(dataSend);
            setPlanetPanchangSunrise(planetPanchangSunriseData);
            console.log('Planet Panchang Sunrise:', planetPanchangSunriseData);

            const chaughadiyaMuhurtaData = await getChaughadiyaMuhurta(dataSend);
            setChaughadiyaMuhurta(chaughadiyaMuhurtaData?.chaughadiya);
            console.log('Chaughadiya Muhurta:', chaughadiyaMuhurtaData);






            const panchangLagnaTableData = await panchangLagnaTable(dataSend);
            setPanchangLagnaTable(panchangLagnaTableData);
            console.log('Panchang Lagna Table:', panchangLagnaTableData);

            const monthlyPanchangData = await monthlyPanchang(dataSend);
            setMonthlyPanchang(monthlyPanchangData);
            console.log('Monthly Panchang:', monthlyPanchangData);

            } catch (error) {
            console.error('Error fetching Panchang data:', error);
            }
            };

            fetchAllData();
            }, [timezone, dataSend,submitAfPanchangData]);
            function formatDate(day, month, year) {
            // Ensure day and month are two digits
            const formattedDay = day.toString().padStart(2, '0');
            const formattedMonth = month.toString().padStart(2, '0');

            // Return the formatted date string
            return `${formattedDay}/${formattedMonth}/${year}`;
            }

            function startTimeCulculate(hour, minute, second){
            // Ensure day and month are two digits
            const formattedHour = hour
            const formattedMinute= minute
            const formattedSecond = second

            // Return the formatted date string
            return `${formattedHour}/${formattedMinute}/${formattedSecond}`;
            }
            function endTimeCulculate(hour, minute, second){
            // Ensure day and month are two digits
            const formattedHour = hour
            const formattedMinute= minute
            const formattedSecond = second

            // Return the formatted date string
            return `${formattedHour}/${formattedMinute}/${formattedSecond}`;
            }




            const formatRecentDate = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
            };

            const nowRecentDate = new Date();
            const formattedDate = formatRecentDate(nowRecentDate);

            const getYesterdayDate = () => {
            const today = new Date();
            today.setDate(today.getDate() - 1);
            return today;
            };

            // Function to format date as dd/mm/yyyy
            const formatDateYesterday = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
            };

            // Get yesterday's date
            const yesterdayDate = getYesterdayDate();

            // Format yesterday's date
            const formattedYesterdayDate = formatDateYesterday(yesterdayDate);
            return (
            <>
            {/* ========= */}
            <Header/>
            {/* ====== */}
            {/* panchang section start */}
            <section className="panchang_section">
            <div className="container">
            {/* panchang title  */}
            <div className="panchang_title_div d-flex ">
            <h1>Daily Panchang </h1>
            <hr />
            </div>
            {/* panchang title end */}
            {/* row start */}
            <form action="">
            <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-3 col-4 rightcol">
            <div className="panchang_input_div">
            <label htmlFor="day">Day</label>
            <select name="day" onChange={changePanchangData} id="day" className="form-select" required>
            <option value="" disabled>Day</option>
            {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
            <option key={day} value={day} selected={day === new Date().getDate()}>{day}</option>
            ))}
            </select>
            </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-3 col-4 leftcol">
            <div className="panchang_input_div">
            <label htmlFor="month">Month</label>
            <select name="month" onChange={changePanchangData} id="month" className="form-select">
            <option value="" disabled>Month</option>
            {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
            <option key={month} value={month} selected={month === new Date().getMonth() + 1}>
            {new Date(0, month - 1).toLocaleString('default', { month: 'short' })}
            </option>
            ))}
            </select>
            </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-3 col-4 leftcol">
            <div className="panchang_input_div">
            <label htmlFor="year">Year</label>
            <select name="year" onChange={changePanchangData} id="year" className="form-select">
            <option value="" disabled>Year</option>
            {Array.from({ length: 134 }, (_, i) => 1890 + i).map(year => (
            <option key={year} value={year} selected={year === new Date().getFullYear()}>{year}</option>
            ))}
            </select>
            </div>
            </div>



            {/* col start */}
            <div className="col-lg-6 col-md-6 col-sm-3 col-8 leftcol">
            <div className="panchang_input_div d-flex gap-2">
            <button type="submit" name="getPanchang" className='panchang_btn' onClick={submitPanchangData}>Get Panchang</button>
            <button  className='panchang_btn' onClick={getTodayPanchang}>Today Panchang</button>
            </div>
            </div>
            {/* col end */}

            </div>
            </div>
            </div>
            </form>
            {/* row end */}
            </div>
            </section>
            {/* panchang section end */}

            {/*  */}

            <section className="panchag_daily_section">
            <div className="container">
            {/* row */}
            <div className="row">
            {/* col start */}
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="left_panchang_daily_txt_div">
            <h2>{parseFloat(advancedPanchangSunrise?.tithi?.details?.tithi_number)<15?advancedPanchangSunrise?.tithi?.details?.tithi_number:advancedPanchangSunrise?.tithi?.details?.tithi_number-15}</h2>
            <h3>{advancedPanchangSunrise?.tithi?.details?.tithi_name} </h3>

            <div className="panchang_daily_table">
            <table class="table table-bordered">
            <tr>
            <th>Date</th>

            <td>
            {submitAfPanchangData 
            ? `${submitAfPanchangData.day}/${submitAfPanchangData.month}/${submitAfPanchangData.year}` 
            : new Date().toLocaleDateString()}
            </td>

            </tr>
            <tr>
            <th>Week Day</th>
            <td>{basicPanchang?.day}</td>
            </tr>
            <tr>
            <th>Start Time</th>
            <td>14:21:48 ({formattedYesterdayDate})</td>
            </tr>
            <tr>
            <th>End Time</th>
            <td> { advancedPanchangSunrise?.tithi?.end_time?.hour}:{ advancedPanchangSunrise?.tithi?.end_time?.minute}:{ advancedPanchangSunrise?.tithi?.end_time?.second}  

            ({formattedDate})</td>
            </tr>
            <tr>
            <th>Maah</th>
            <td>{advancedPanchangSunrise?.hindu_maah?.purnimanta}</td>
            </tr>
            <tr>
            <th>Paksha</th>
            <td>{advancedPanchangSunrise?.paksha}</td>
            </tr>
            </table>
            </div>
            </div>
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            {/* nested row start */}
            <div className="row nested_row">
            {/* col nested start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="mahurat_title_div">
            <img src="../images/pan5.png" alt="" className='img-fluid'/>
            <h3>Current Muhurat </h3>
            </div>
            </div>
            {/* col nested end */}
            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="#horaTablePopUp" data-bs-toggle="modal">
            <h4>Hora</h4>
            <p>Jupiter</p>
            <p>11:00 - 12:00</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link  to="#chaughadiyaTablePopUp" data-bs-toggle="modal">
            <h4>Chogadia</h4>
            <p className=''>{currentMuhurta}</p>
            <p>{currentTime}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Current Lagna</h4>
            <p>Virgo</p>
            <p>11:02 - 12:53</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}


            {/* col nested start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="mahurat_title_div">
            <img src="../images/pan2.png" alt="" className='img-fluid'/>
            <h3>Today's Panchang</h3>
            </div>
            </div>
            {/* col nested end */}


            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg2_div_main">
            <div className="mahurat_txt_div_main" onClick={toggleTodayPanchangOpen}>
            <Link >
            <h4>Tithi</h4>
            <p>{advancedPanchangSunrise?.tithi?.details?.tithi_name}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg2_div_main">
            <div className="mahurat_txt_div_main">
            <Link onClick={toggleTodayPanchangOpen} >
            <h4>Yoga</h4>
            <p>{advancedPanchangSunrise?.yog?.details?.yog_name}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}


            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg2_div_main">
            <div className="mahurat_txt_div_main">
            <Link onClick={toggleTodayPanchangOpen}>
            <h4>Karana</h4>
            <p>Gara</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg2_div_main">
            <div className="mahurat_txt_div_main">
            <Link onClick={toggleTodayPanchangOpen}>
            <h4>Nakshatra</h4>
            <p>Ashwini</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg2_div_main">
            <div className="mahurat_txt_div_main">
            <Link onClick={toggleTodayPanchangOpen}>
            <h4>Paksha</h4>
            <p>Krishna-Pakfffsha</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}


            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg2_div_main">
            <div className="mahurat_txt_div_main">
            <Link onClick={toggleTodayPanchangOpen}>
            <h4>Vara</h4>
            <p>Sunday</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="mahurat_title_div">
            <img src={`${Domain_Url}/images/hindumonth.png`} alt="" className='img-fluid'/>
            <h3>Hindu Month & Year  </h3>
            </div>
            </div>
            {/* col nested end */}
            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Disha Shoola</h4>
            <p>{basicPanchangSunrise?.sunrise}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Abhijit</h4>
            <p>18:7:28</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Yamaghanta</h4>
            <p>0:51:36</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}


            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Gulika Kaal</h4>
            <p>13:15:4</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}



            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Rahu Kaal</h4>
            <p>Grishm</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}



            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Moon Sign</h4>
            <p>Aries</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}



           

            {/* Hindu Month & Year */}


            {/* Auspicious & Inauspicious Timings  */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="mahurat_title_div">
            <img src="../images/pan3.png" alt="" className='img-fluid'/>
            <h3>Sun & Moon Calculations   </h3>
            </div>
            </div>
            {/* col nested end */}
            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Sun Rise</h4>
            <p>{advancedPanchangSunrise?.sunrise}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Sun Set</h4>
            <p>{advancedPanchangSunrise?.sunset}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Moon Rise</h4>
            <p>{advancedPanchangSunrise?.moonrise}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}


            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Moon Set</h4>
            <p>{advancedPanchangSunrise?.moonset}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}



            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Ritu</h4>
            <p>{advancedPanchangSunrise?.ritu}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}



            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Moon Sign</h4>
            <p>{advancedPanchangSunrise?.moon_sign}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* Auspicious & Inauspicious Timings  */}
 {/* Hindu Month & Year  */}
 <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="mahurat_title_div">
            <img src={`${Domain_Url}/images/ausinaus.png`} alt="" className='img-fluid'/>
            <h3>Auspicious & Inauspicious Timings</h3>
            </div>
            </div>
            {/* col nested end */}
            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>
            Disha Shoola</h4>
            <p>{advancedPanchang?.disha_shool}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Abhijit</h4>
            <p>18:7:28</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}

            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Yamaghanta</h4>
            <p>0:51:36</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}


            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Gulika Kaal</h4>
            <p>13:15:4</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}



            {/* col nested start */}
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 mahurat_bg_div_main">
            <div className="mahurat_txt_div_main">
            <Link to="">
            <h4>Rahu Kaal</h4>
            <p>{advancedPanchang?.rahukaal?.start}-{advancedPanchang?.rahukaal?.end}</p>
            </Link>
            </div>
            </div>
            {/* col nested end */}
            </div>
            {/* nested row end */}
            </div>
            {/* col end */}
            </div>
            {/* row end */}
            </div>
            </section>
            {/*  */}

            {/* all poups start */}
            {/* HORA TABLE POP UP*/}
            <div className="hora_deatils_popup_div_main">
            <div className="modal fade" id="horaTablePopUp" tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
            <h1 class="modal-title" id="staticBackdropLabel">Hora Details</h1>
            <button type="button" className="fa fa-times" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <div className="hora_detals_table_name">
            <h3>Current Hora</h3>
            <p>12:00 - 13:00 ( Mercury )</p>
            </div>
            <div className="hora_detals_table_main_div panachnagTable">
            <table className="table table-bordered table-sm">
            <tbody>
            <tr>
            <th>Planet</th>
            <th>Start Time</th>
            <th>End Time</th>
            </tr>
            <tr>
            <td>Moon</td>
            <td>6:0</td>
            <td>7:0</td>
            </tr>
            <tr>
            <td>Saturn</td>
            <td>7:0</td>
            <td>8:0</td>
            </tr>
            <tr>
            <td>Jupiter</td>
            <td>8:0</td>
            <td>9:0</td>
            </tr>
            <tr>
            <td>Mars</td>
            <td>9:0</td>
            <td>10:0</td>
            </tr>
            <tr>
            <td>Sun</td>
            <td>10:0</td>
            <td>11:0</td>
            </tr>
            <tr>
            <td>Venus</td>
            <td>11:0</td>
            <td>12:0</td>
            </tr>
            <tr>
            <td>Mercury</td>
            <td>12:0</td>
            <td>13:0</td>
            </tr>
            <tr>
            <td>Moon</td>
            <td>13:0</td>
            <td>14:0</td>
            </tr>
            <tr>
            <td>Saturn</td>
            <td>14:0</td>
            <td>15:0</td>
            </tr>
            <tr>
            <td>Jupiter</td>
            <td>15:0</td>
            <td>16:0</td>
            </tr>
            <tr>
            <td>Mars</td>
            <td>16:0</td>
            <td>17:0</td>
            </tr>
            <tr>
            <td>Sun</td>
            <td>17:0</td>
            <td>18:0</td>
            </tr>
            <tr>
            <td>Venus</td>
            <td>18:0</td>
            <td>19:0</td>
            </tr>
            <tr>
            <td>Mercury</td>
            <td>19:0</td>
            <td>20:0</td>
            </tr>
            <tr>
            <td>Moon</td>
            <td>20:0</td>
            <td>21:0</td>
            </tr>
            <tr>
            <td>Saturn</td>
            <td>21:0</td>
            <td>22:0</td>
            </tr>
            <tr>
            <td>Jupiter</td>
            <td>22:0</td>
            <td>23:0</td>
            </tr>
            <tr>
            <td>Mars</td>
            <td>23:0</td>
            <td>0:0</td>
            </tr>
            <tr>
            <td>Sun</td>
            <td>0:0</td>
            <td>1:0</td>
            </tr>
            <tr>
            <td>Venus</td>
            <td>1:0</td>
            <td>2:0</td>
            </tr>
            <tr>
            <td>Mercury</td>
            <td>2:0</td>
            <td>3:0</td>
            </tr>
            <tr>
            <td>Moon</td>
            <td>3:0</td>
            <td>4:0</td>
            </tr>
            <tr>
            <td>Saturn</td>
            <td>4:0</td>
            <td>5:0</td>
            </tr>
            <tr>
            <td>Jupiter</td>
            <td>5:0</td>
            <td>6:0</td>
            </tr>
            </tbody></table>    
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            {/*  */}


            {/* HORA TABLE POP UP*/}
            <div className="hora_deatils_popup_div_main">
            <div className="modal fade" id="chaughadiyaTablePopUp" tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
            <h1 class="modal-title" id="staticBackdropLabel">Chogadia Details</h1>
            <button type="button" className="fa fa-times" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <div className="hora_detals_table_name">
            <h3>Current Chogadia</h3>
            <p>{currentTime} ( {currentMuhurta} )</p>
            </div>
            <div className="hora_detals_table_main_div">
            <table className="table table-bordered table-sm">
            <tbody>
            <tr>
            <th>Chogadia</th>
            <th>Start Time</th>
            <th>End Time</th>
            </tr>

            {allChaughadiyaTimings?.map((ele)=> <tr>
            <th>{ele?.muhurta}</th>
            <th>{ele?.time.slice(0, 8)}</th>
            <th>{ele?.time.slice(11, 20)}</th>
            </tr>)}

            </tbody></table>    
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            {/*  */}
            {/* Chaughadiya Details  */}
            <div className="hora_deatils_popup_div_main">
            <div className="modal fade" id="chaughadiyaTablePopUp" tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
            <h1 class="modal-title" id="staticBackdropLabel">Chogadia Details</h1>
            <button type="button" className="fa fa-times" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <div className="hora_detals_table_name">
            <h3>Current Chogadia</h3>
            <p>{currentTime} ( {currentMuhurta} )</p>
            </div>
            <div className="hora_detals_table_main_div">
            <table className="table table-bordered table-sm">
            <tbody>
            <tr>
            <th>Chogadia</th>
            <th>Start Time</th>
            <th>End Time</th>
            </tr>

            {allChaughadiyaTimings?.map((ele)=> <tr>
            <th>{ele?.muhurta}</th>
            <th>{ele?.time.slice(0, 8)}</th>
            <th>{ele?.time.slice(11, 20)}</th>
            </tr>)}

            </tbody></table>    
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            {/* Chaughadiya Details  */}



            {/*  lagna Table*/}
            <div className="hora_deatils_popup_div_main">
            <div className="modal fade" id="TablePopUp" tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
            <h1 class="modal-title" id="staticBackdropLabel">Lagna Table</h1>
            <button type="button" className="fa fa-times" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <div className="hora_detals_table_name">
            <h3>Current Chogadia</h3>
            <p>{currentTime} ( {currentMuhurta} )</p>
            </div>
            <div className="hora_detals_table_main_div">
            <table className="table table-bordered table-sm">
            <tbody>
            <tr>
            <th>Chogadia</th>
            <th>Start Time</th>
            <th>End Time</th>
            </tr>

            {allChaughadiyaTimings?.map((ele)=> <tr>
            <th>{ele?.muhurta}</th>
            <th>{ele?.time.slice(0, 8)}</th>
            <th>{ele?.time.slice(11, 20)}</th>
            </tr>)}

            </tbody></table>    
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>

            {/*  lagna Table  */}

            {/*  Tithi*/}
          
           {openTodayPanchang && 
           <div className="hora_deatils_popup_div_main">
            <div className="modal" style={{display:"block"}}>
            <div className="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
            <h1 class="modal-title" id="staticBackdropLabel">Today's Panchang</h1>
            <button type="button" className="fa fa-times"onClick={toggleTodayPanchangOpen}></button>
            </div>
            <div className="modal-body">
            <div className="hora_detals_table_name">
            <h3>Current Chogadia</h3>
            <p>{currentTime} ( {currentMuhurta} )</p>
            </div>
            <div className="hora_detals_table_main_div">
            <table className="table table-bordered table-sm">
            <tbody>
            <tr>
            <th>Tithi</th>
            <th>{advancedPanchangSunrise?.tithi?.details?.tithi_name}</th>

            </tr>

            <tr>
            <th>Yoga</th>
            <th>{advancedPanchangSunrise?.yog?.details?.yog_name}</th>

            </tr>
            <tr>
            <th>Karana</th>
            <th>{advancedPanchangSunrise?.karan?.details?.karan_name}</th>

            </tr>

            <tr>
            <th>Paksha</th>
            <th>{advancedPanchangSunrise?.paksha}</th>
            </tr>
            <tr>
            <th>Nakshatra</th>
            <th>{advancedPanchangSunrise?.nakshatra?.details?.nak_name}</th>
            </tr>


            <tr>
            <th>Vara</th>
            <th>{basicPanchang?.day}</th>

            </tr>

            
         



            </tbody></table>    
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            
            }

            {/*  Tithi  */}
            </>
            )
            }

            export default Panchang
