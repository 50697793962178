import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Securepyments = () => {
  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}
       {/* Verifiedastrologers section start */}
       <section className="verifiedastrologers_section">
  <div className="container">
    {/*  */}
    <div className="verifiedastrologers_title_div">
      <h1>Secure Payments</h1>
    </div>
    {/*  */}

    {/* col start */}
    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="verifiedastrologers_txt_div">
            <p>At HealFate, we assure you: our astrologers/Experts are verified. Trust is our foundation, and we ensure authenticity every step of the way.</p>
            
            <h2>Verification Process:</h2>
            <p>We're serious about making sure our astrologers/Experts/ are top-notch. We check their qualifications, experience, and ethics carefully. After which they go through a screening test and training session. This way, you can trust that they're skilled and trustworthy.</p>

            <p>We're here to support you on your journey. Our astrologers/Experts aren't just giving advice, they're your partners in navigating life's ups and downs. We're dedicated to helping you find clarity and confidence. When you choose HealFate, you're choosing reliability. Our verified astrologers/Experts are here to guide you, every step of the way, on your quest for understanding and growth.</p>
        </div>
    </div>
    {/* col end */}
   
  </div>
</section>

      {/* Verifiedastrologers section end */}

        {/* footer componets start */}
        <Footer/>
      {/*footer componets end */}
    </>
  )
}

export default Securepyments
