import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { sliderrigtside, todayhoroscopecarousel } from '../data/Owlcarouseloptions';
import { monthlyHoroscopePredictioApi, monthlyHoroscopePredictionApi, sliderApi, todayHoroscopePredictionApi, tomorrowHoroscopePredictionApi } from '../data/AllapiData';
import { Domain_Url } from '../components/Url/Url';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LoadingComponent from '../components/LoadingComponent/LoadingComponent';
import '../CssFiles/Horoscope.css';

const HoroscopeDetail = () => {
  const navigate = useNavigate();
  const [allHoroscopeData, setAllHoroscopeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id, horosName } = useParams();
  const [monthlyHoroscope,setMonthlyHoroscope] =useState(false)
  const [monthlyHoroscopeData,setMonthlyHoroscopeData]=useState()
  console.log(monthlyHoroscopeData)
  const [slider,setSlider]=useState()
  
  console.warn(slider)
 async function fetchSlider(){
    const res= await sliderApi()
    if(res?.success==true){
      setSlider(res?.data)
    }
  }

  useEffect(()=>{
    fetchSlider()
  },[])
   console.error(allHoroscopeData)
  // Generate timezone
  const timezone =  new Date().getTimezoneOffset() / -60; //

  const fetchTodayHoroscope = async () => {
    const dataSend = { timezone, horosName };
    try {
      const res = await todayHoroscopePredictionApi(dataSend);
      console.log(res)
      if(res?.status==true){
        setLoading(true);
      setAllHoroscopeData(res);
      
    }
    } catch (error) {
      console.error('Error fetching today horoscope:', error);
    }
  };

  const fetchTomorrowHoroscope = async () => {
    const dataSend = { timezone, horosName };
    try {
      const res = await tomorrowHoroscopePredictionApi(dataSend);
      setAllHoroscopeData(res);
    } catch (error) {
      console.error('Error fetching tomorrow horoscope:', error);
    }
  };

  const fetchMonthlyHoroscope = async () => {
    const dataSend = { timezone, horosName };
    try {
      const res = await monthlyHoroscopePredictionApi(dataSend);
      setMonthlyHoroscopeData(res);
    } catch (error) {
      console.error('Error fetching monthly horoscope:', error);
    }
  };
  useEffect(() => {

    // Check if the id matches the expected values
    if (horosName !== 'aries' && horosName !== 'taurus'   && horosName !== 'gemini'  && horosName !== 'cancer'   && horosName !== 'leo'   && horosName !== 'virgo'  && horosName !== 'libra'  && horosName !== 'scorpio'    && horosName !== 'sagittarius'  && horosName !== 'capricorn'   && horosName !== 'aquarius'   && horosName !== 'pisces') {
        // Navigate to the homepage if id is not valid
        navigate('/');
    }
    // Dependency array includes id so useEffect runs when id changes
}, [id, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      if (id == 'today-horoscope') {
        await fetchTodayHoroscope();
      } else if (id == 'tomorrow-horoscope') {
        await fetchTomorrowHoroscope();
      } else if (id == 'monthly-horoscope') { // Updated to handle 'monthly-horoscope'
        setMonthlyHoroscope(!monthlyHoroscope)
        await fetchMonthlyHoroscope();
      }
    };

    fetchData();
  }, [id, horosName]); // Added horosName to dependencies in case it changes



  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}

   {loading? <>
     {/* horoscope details section start */}
     <section className="horoscope_details_section">
        <div className="container">
            {/* row start */}
            <div className="row">
                {/*======================= col start 9============================= */}
                <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                    {/* title */}
                    <div className="horoscope_dt_title_img_box">
                            <div className="horoscope_img_box_dt">
                                <img src="../logo/logo.png" alt="" className='img-fluid'/>
                            </div>
                            <h1>{allHoroscopeData?.prediction_date} Prediction For {monthlyHoroscopeData?.prediction_month} </h1>
                            <hr />
                        </div>
                    {/* title end */}

                    
                    <div className="maindiv_horoscope_details_leftsode">
                     {  
                      
                   !monthlyHoroscope ?   <>
                         <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                                <img src={`${Domain_Url}/images/horoscope1.png`} alt="" className='img-fluid'/>
                                <h2>Personal Life</h2>
                            </div>
                            <p>{allHoroscopeData?.prediction?.personal_life}</p>
                        </div>  
                       

                        
                        <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                            <img src={`${Domain_Url}/images/horoscope2.png`} alt="" className='img-fluid'/>
                                <h2>Profession</h2>
                            </div>
                            <p>{allHoroscopeData?.prediction?.profession}</p>
                        </div>  
                       

                         
                         <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                            <img src={`${Domain_Url}/images/horoscope3.png`} alt="" className='img-fluid'/>
                                <h2>Health</h2>
                            </div>
                            <p>{allHoroscopeData?.prediction?.health}</p>
                        </div>  
                       


                         
                         <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                            <img src={`${Domain_Url}/images/horoscope4.png`} alt="" className='img-fluid'/>
                                <h2>Travel</h2>
                            </div>
                            <p>{allHoroscopeData?.prediction?.travel}</p>
                        </div>  
                       


                         
                         <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                            <img src={`${Domain_Url}/images/horoscope5.png`} alt="" className='img-fluid'/>
                                <h2>Luck</h2>
                            </div>
                            <p>भाग्य आपके पक्ष में है; अतएव आपका मूड बहुत अच्छा रहेगा । आप के लॉटरी जीतने या सट्टा बाजार में लाभ हासिल करने की अच्छी संभावनाएं हैं ।</p>
                        </div>  
                       

                          
                          <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                            <img src={`${Domain_Url}/images/horoscope6.png`} alt="" className='img-fluid'/>
                                <h2>Emotion</h2>
                            </div>
                            <p>{allHoroscopeData?.prediction?.emotions}</p>
                        </div>  
                       
</>:
 <div className="main_div_txt">

 { monthlyHoroscopeData?.prediction?.map((ele)=>
 <p>{ele}</p>)}
</div>  
}
                    </div>
                    
                </div>
          {/*======================= col end 9============================= */}

          
{/* ===================col start right side 3 col========================  */}
<div className="col-lg-3 col-md-3 col-sm-12 col-12">
  <div className="right_side_main_div_sticky mb-2">

    {/* slider start */}
    <div class="sidebar_main_div_sld">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/sld01.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  {/* slider start */}
  <div class="sidebar_main_div_sld2">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/gplay.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/apple01.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  
  {/* app div */}
  <div className="app_download">
    <h2>TalkndHeal on Mobile</h2>
    <Link to="">Download Now</Link>
  </div>
  {/*  */}
  {/* left icons start */}
  <div className="left_icons_so">
    {slider?.map((ele)=>
    <Link target="_blank" to=""><img src={`${ele?.url}/${ele?.image}`} alt="Android App" /></Link>
    )}
   
  </div>
  {/* left icons end */}

 
  </div>
</div>
{/* ===================col end right side 3 col========================  */}

            </div>
            {/* row end */}
        </div>
     </section>
     {/* horoscope details section end */}


{/*  */}
<section className="todayhoroscope_section">
  <div className="container">
  
    <div className="row">
      <div className="col-lg-12">
        {/*  */}
          <div className="todayhoroscope_service">
          <OwlCarousel className="owl-theme" {...todayhoroscopecarousel}>

{/* item div start */}
 {/*  */}

            <div className="item">
            <Link to="/aries">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/1.png`}/>
            </div>
              <h3>Aries</h3>
          </div>
        </Link>
            </div>
          
          {/*  */}
          {/*  */}
         
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/2.png`} />
            </div>
              <h3  to="taurus">Taurus</h3>
          </div>
        </Link>
            </div>
         
          {/*  */}
          {/*  */}
          
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/3.png`} />
            </div>
              <h3>Gemini</h3>
          </div>
        </Link>
            </div>
         
          {/*  */}

          {/*  */}
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/4.png`} />
            </div>
              <h3>Cancer</h3>
          </div>
        </Link>
            </div>
          {/*  */}

          
          {/*  */}
          <div className="item">
          <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/5.png`}/>
            </div>
              <h3>Leo</h3>
          </div>
        </Link>
            </div>
          {/*  */}


          
          {/*  */}
          <div className="item">
          <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/6.png`} />
            </div>
              <h3>Virgo</h3>
          </div>
        </Link>
            </div>
          {/*  */}


          
          {/*  */}
          <div className="item">
          <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/7.png`}/>
            </div>
              <h3>Libra</h3>
          </div>
        </Link>
            </div>
          {/*  */}


          
          {/*  */}
          <div className="item">
          <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/8.png`}/>
            </div>
              <h3>Scorpio</h3>
          </div>
        </Link>
            </div>
          {/*  */}

             {/*  */}
             <div className="item">
             <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/9.png`}/>
            </div>
              <h3>Sagittarius</h3>
          </div>
        </Link>
            </div>
          {/*  */}

            {/*  */}
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/10.png`} />
            </div>
              <h3>Capricorn</h3>
          </div>
        </Link>
            </div>
          {/*  */}


            {/*  */}
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/11.png`} />
            </div>
              <h3>Aquarius</h3>
          </div>
        </Link>
            </div>
          {/*  */}

          
            {/*  */}
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src={`${Domain_Url}/images/12.png`}/>
            </div>
              <h3>Pisces</h3>
          </div>
        </Link>
            </div>
          {/*  */}




</OwlCarousel>
</div>  
       
      </div>
    </div>
  </div>
</section>
{/*  */}
</>:<LoadingComponent/>}

<Footer/>
    </>
  )
}

export default HoroscopeDetail
