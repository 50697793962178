import React, { useState } from "react";

import AstrologerChatCard from "../components/AstrologerChatCard/AstrologerChatCard";


const AstrologerHome = () => {
    
   

    return (
        <>
           
            <AstrologerChatCard/>
          
          </>
    );
};

export default AstrologerHome;
