function LoadingComponent() {
   
  
    return (
     
  
      <div className="d-flex gap-3 align-items-center justify-content-center fs-1 text-danger" style={{height:"100vh"}}>
 
    
          
 <img src={require('../assets/img/preloaderDesign.gif')} height={400} width={400}></img>
 
 


      </div>
    
    );
  }
  
  export default LoadingComponent;
  