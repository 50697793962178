import React, { useCallback, useEffect, useRef, useState } from 'react'
import './LiveChat.css'
import { FaGift, FaWallet } from "react-icons/fa6";
import { IoTimer } from "react-icons/io5";
import { IoIosSend, IoMdSend } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { LiveChatAssistantPostApi, getLiveChatAssistantApi, getSingleAstrologerApi } from '../../AllApi/AllApi';
import { Col, Container, Row } from 'react-bootstrap';
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { cookieDomain } from '../Url/Url';
import GiftPage from '../GiftPage/GiftPage';
import { RxCross1 } from 'react-icons/rx';


const ChatAssistant = () => {
  const navigate = useNavigate()
  const navigateback = useNavigate();
  const [messageData, setMessageData] = useState({
    message: '',
    image: null,
  });
  //   message get after 1 second
  //   message get after 1 second
  const [seconds, setSeconds] = useState(
    Number(sessionStorage.getItem('timerSeconds')) || 0
  );
  const [refressStatus, setRefressStatus] = useState()
  const [chatUrl, setChatUrl] = useState()
  const {chatId,astroId,mainIId} = useParams();

 




  const senderid = Cookies.get('user')
  const userid = senderid
  const [typing, setTyping] = useState(false); // State to track if user is typing
  const [userDetails, setUserDetails] = useState({})
  const [firstFreeChat, setFirstFreeChat] = useState()

  const [astrologerDetails, setAstrologerDetails] = useState({})
  
  //  Wallet Logic 

  const [chatOpen, setChatOpen] = useState(false);
  const [chatInitiate, setchatInitiate] = useState(false)




  const [url, setUrl] = useState()



  const [imagePreview, setImagePreview] = useState(null);
  const [allChatMessages, setAllChatMessages] = useState([])
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Scroll to the bottom of the chat container when new messages are added
    const chatContainer = document.querySelector('.chat-container');
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [allChatMessages]);

  function changeMessageData(e) {
    if (e.target.name === 'image') {
      const file = e.target.files[0];
      if (file) {
        setImagePreview(URL.createObjectURL(file));
        setMessageData({ ...messageData, image: file });
      }
    } else {
      const newValue = e.target.value;
      // Count the number of digits in the new value
      const numberCount = (newValue.match(/\d/g) || []).length;
      if (numberCount <= 10) {
        setMessageData({ ...messageData, [e.target.name]: newValue });
      } else {
        alert("You cannot include more than 10 numbers in the message.");
      }
    }
    setTyping(true);
  }
  
  

  function removeImage() {
    setImagePreview(null);
    setMessageData({ ...messageData, image: null });
  }

  //   submit message data 
  async function submitMessageData(event) {
    event.preventDefault();
    setChatOpen(true);

    const type = 1;
    const chatid = chatId;
    const receiver_id = astroId;

    if (messageData.message.trim() !== '' || messageData.image !== null) {
      const ressend = { senderid, userid, ...messageData, chatid, receiver_id, type };

      try {
        const res = await LiveChatAssistantPostApi(ressend);

        if (res?.success) {
          setMessageData({ message: '', image: null });
          setImagePreview(null);
          setTyping(false);
        } else {
        }
      } catch (error) {
      }
    } else {
    }
  }

  const isSendDisabled = messageData.message.trim() === '' && !messageData.image;

  //   submit message data 



 
 




  // first chat 



  //   profile details get 


  //   get All Datas 

  async function messageDataGetApi(id) {
    const res = await getLiveChatAssistantApi(id);
    if (res?.success) {
      setAllChatMessages(res?.data);
      setChatUrl(res?.chatimageurl)
    }
  }
  //   get All Datas 







  function extractTime(dateTimeString) {
    if (dateTimeString === null || dateTimeString === undefined) {
      return 'Invalid datetime';
    }

    if (typeof dateTimeString !== 'string') {
      return 'Invalid datetime';
    }

    const parts = dateTimeString.split(' ');
    if (parts.length !== 2 || !parts[1]) {
      return 'Invalid datetime';
    }

    const [hours24, minutes, seconds] = parts[1].split(':');
    let hours12 = parseInt(hours24, 10);
    const ampm = hours12 >= 12 ? 'PM' : 'AM';

    if (hours12 === 0) {
      hours12 = 12;
    } else if (hours12 > 12) {
      hours12 -= 12;
    }

    return `${hours12}:${minutes} ${ampm}`;
  }


  let lastDateString = null;
  function formatDateTime(dateTimeStr) {
    const now = new Date();
    const dateTime = new Date(dateTimeStr);
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    let dateString;
    if (dateTime >= today && dateTime < tomorrow) {
      dateString = "Today";
    } else if (dateTime >= tomorrow) {
      dateString = "Tomorrow";
    } else {
      dateString = dateTime.toDateString(); // Return the full date if not today or tomorrow
    }
    if (dateString === "Today" && lastDateString === "Today") {
      return null; // Return null if the date is the same as the last date
    } else {
      lastDateString = dateString;
      return dateString + ", " + dateTime.toLocaleTimeString();
    }
  }











  //   message get after 1 second


  useEffect(() => {
    const savingId = chatId;
    messageDataGetApi(savingId);
    const intervalId = setInterval(() => {
      messageDataGetApi(savingId);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [chatOpen]);


 

  const chatContainerRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);

  useEffect(() => {
    scrollToBottom();
  }, [allChatMessages]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    const isBottom = scrollTop + clientHeight >= scrollHeight;
    setIsAtBottom(isBottom);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current && isAtBottom) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  // Update isAtBottom when allChatMessages changes and user is at bottom
  useEffect(() => {
    if (isAtBottom) {
      scrollToBottom();
    }
  }, [allChatMessages, isAtBottom]);





  const [intervalId, setIntervalId] = useState(null);

  const toggleModal = (id) => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const savedStartTime = parseInt(sessionStorage.getItem('timerStartTime'), 10);
    const savedElapsedSeconds = parseInt(sessionStorage.getItem('timerSeconds'), 10);

    if (!isNaN(savedStartTime)) {
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedSeconds = currentTime - savedStartTime + (savedElapsedSeconds || 0);
      setSeconds(elapsedSeconds);
    }

  

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);







  async function getAstrologerDetails() {
    const userId = Cookies.get('user')
  
    const dataSend = { userId, mainIId }
    const res = await getSingleAstrologerApi(dataSend)
    if (res?.success == true) {
      setUrl(res?.url)
      setAstrologerDetails(res?.data)

    }
  }
  useEffect(() => {
    getAstrologerDetails()

  }, [])








  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const [isModalOpenMobile, setIsModalOpenMobile] = useState(false);
  const [modalImageMobile, setModalImageMobile] = useState('');

  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModalMobile = (imageUrl) => {
    setModalImageMobile(imageUrl);
    setIsModalOpenMobile(true);
  };

  const closeModalMobile = () => {
    setIsModalOpenMobile(false);
  };


  return (
    <>

      <div className=''>
        <div className="container containerLive" style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
          <div className="chat_box" style={{ flex: "1 0 auto", display: "flex", flexDirection: "column" }}>

            {/* Head */}
            <div className='row  ' style={{ flex: "0 1 auto" }}>
              <div className=" chat_ui  col-12 d-flex align-items-center justify-content-between" style={{ padding: "10px" }}>
               
              <div className='d-flex align-items-center justify-content-start'><div >
                  <div style={{ position: "relative" }}>
                    <div className="astrologer_profile_image">
                      <img  src="../images/baba.png"  alt="" style={{width: "50px",   height: "50px", objectFit: "cover", borderRadius: "100px",
                        }}
                      />
                    </div>
                    <div  style={{ position: "absolute",
                        top: "0px",
                        left: "35px",
                        zIndex: "3",
                      }}
                    >
                      <img
                        src={require("../assets/img/verified_red_icon.png")}
                        alt=""
                        width="16px"
                        height="16px"
                      />
                    </div>
                  </div>
                </div>
               <div className='text-black'> {astrologerDetails?.name}</div></div>
             
               <div>    
                
               <div className="d-flex justify-content-end" style={{ width: "200px" }}>
                <div className="back-icon "
                            style={{ backgroundColor: 'none' }}>
                            <button
                              onClick={() => navigateback(-1)}
                              style={{ background: "none", border: "none"}}
                            >
                              <i
                                className="bx bx-arrow-back"
                                style={{
                                  fontWeight: "600",
                                 
                                  color: "black",
                                 
                                  fontSize: "20px",
                                  zIndex: 5
                                }}
                              ></i>
                            </button>
                          </div>
                </div>
                          
                          </div>
               
              

               
               
              </div>
            </div>

            {/* Chat Messages and Footer Wrapper */}
            <div className="backgroundPic " style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", borderRadius: "10px", overflow: "hidden" }}>
              {/* Body */}
              <div className='row   scroll-style2' style={{ flex: "1 1 auto", overflowY: "auto", height: "100%" }}>
                <div className="col-12 d-flex flex-column align-items-stretch">
                  <div className='mt-2 w-100 ' style={{ height: "100%", width: "100%" }}>
                 <div className="body" ref={chatContainerRef} onScroll={handleScroll} style={{ padding: "10px", overflowY: "auto", flexGrow: "1" }}>
                      {allChatMessages.map((ele, index) => (
                        <div key={index}>
                          <div className='d-flex align-items-center justify-content-center'>
                            <div className='text-danger my-sm-2 my-2'>
                              {formatDateTime(ele.datetime)}
                            </div>
                          </div>
                          <div className={ele.type == 1 ? 'outgoing' : 'incoming'}>
                            <div className="bubble incoming">
                              {ele?.image == "" ? <p className='text-black' style={{ wordWrap: 'break-word' }}>{ele.message}</p> :
                                <>
                                  <p className='text-black' style={{ wordWrap: 'break-word' }}>{ele.message}</p>

                                  {/* <img className='text-black' style={{ wordWrap: 'break-word' }} src={`${chatUrl}${ele.image}`} alt=""></img> */}

                                  <img
                                    className='text-black'
                                    style={{ wordWrap: 'break-word' }}
                                    src={`${chatUrl}${ele.image}`}
                                    alt=""
                                    onClick={() => openModalMobile(`${chatUrl}${ele.image}`)}
                                  />

                                </>
                              }
                              <p className='text-black' style={{ textAlign: "right", fontSize: "10px" }}>{extractTime(ele.datetime)}</p>
                            </div>
                          </div>

                          <div >
                            {isModalOpenMobile && (
                              <div className="imgmodalMobile" onClick={closeModalMobile}>
                                <div className="imgmodal_content_Mobile d-flex justify-content-center" >
                                  <img src={modalImageMobile} alt='' style={{ objectFit: "auto" }}/>
                                </div>

                                {/* <div className="imgclose-button" onClick={closeModalMobile}>X</div> */}

                                <div className="imgclose_button_mobile" onClick={closeModalMobile}>
                                  <RxCross1 className='fs-3 fw-bold' />
                                </div>
                              </div>
                            )}

                           


                          </div>

                        </div>
                      ))}

                      {typing && (
                        <div className="typing">
                          <div className="bubble">
                            <div className="ellipsis dot_1"></div>
                            <div className="ellipsis dot_2"></div>
                            <div className="ellipsis dot_3"></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer */}
             {  allChatMessages.length<5 ? <div className="row" style={{ flex: "0 1 auto" }}>
                <div className="col-12 d-flex align-items-center">
                  <form onSubmit={submitMessageData} className='d-flex gap-1' style={{ width: "100%" }}>
                    <div className='d-flex w-100 px-2' style={{ border: "1px solid #804c35", borderRadius: "25px", position: "relative" }}>
                      {imagePreview && (
                        <div className='bg-white d-flex justify-content-center p-1' style={{ position: "absolute", bottom: "100%", left: "10px", zIndex: 1, border: "1px solid #f1f1f1", width: "70px", height: "50px" }}>
                          <img src={imagePreview} alt="Selected" style={{ maxHeight: "50px", borderRadius: "5px" }} />
                          <button type="button" onClick={removeImage} style={{ position: "absolute", top: "0", right: "0", backgroundColor: "#804c35", color: "white", border: "none", borderRadius: "50%", width: "13px", height: "13px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }}>x</button>
                        </div>
                      )}
                      <input
                        type="text"
                        autoComplete="off"
                        className="msg styles  flex-grow-1"
                        placeholder="Type a message..."
                        name='message'
                        value={messageData.message}
                        onChange={changeMessageData}
                        style={{ width: "100%", fontSize: "12px", padding: "5px" }}
                      />
                      <label htmlFor="file-input" style={{ display: "flex", alignItems: "center", padding: "0 5px", cursor: "pointer" }}>
                        <i className='bx bxs-image fs-4' style={{ color: "#804c35" }}></i>
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        style={{ display: "none" }}
                        onChange={changeMessageData}
                        name='image'
                      />
                      <button type="submit" disabled={isSendDisabled} style={{ border: "none", background: "none", padding: "0 5px", cursor: isSendDisabled ? 'not-allowed' : 'pointer' }}>
                        <img
                          src={require("../assets/img/send_msg.png")}
                          alt="Send"
                          style={{ height: "18px", width: "18px" }}
                        />
                      </button>
                    </div>
                   
                  </form>
                </div>
              </div>:<p className='text-center text-danger fs-5'>You Can't Send more than 5 messages in a day.</p>}
            </div>
          </div>
        </div>

      </div>

    
    </>
  )
}

export default ChatAssistant