// chat api ===================start
export const fetchastrochatData = async () =>{
    const res = await fetch('https://healfate.com/custom_api/astrologerAPIs/fetch_astrologers_chat.php',{
        method:"Get",
        headers:{
            
         "Content-Type": "application/json",
        },      
    });

    return await res.json();
}

// chat api ===================end


// call api ===================start

export const fetchastrocallData = async () =>{
    const res = await fetch('https://healfate.com/custom_api/astrologerAPIs/fetch_astrologers_call.php',{
        method:"Get",
        headers:{
         "Content-Type": "application/json",
        },      
    });

    return await res.json();
}

// call api ===================end

// video api ===================start

export const fetchvideoData = async () =>{
    const res = await fetch ('https://healfate.com/custom_api/astrologerAPIs/fetch_astrologers_videochat.php',{
        method:"Get",
        headers:{
         "Content-Type": "application/json",
        }, 
    });
    return await res.json();
}

// video api ===================end


//All services Api's 
export const singleServicesApi = async (Data) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/fetch_single_expertise.php?service=${Data}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
        }

        return await res.json();
    } catch (error) {
        console.error('Error fetching single service:', error);
        throw error; // Optionally re-throw the error if you want to handle it further up the call chain
    }
};


// 
export const allServicesApi = async () => {
    try {
        const res = await fetch('https://healfate.com/custom_api/fetch_expertise.php?service', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
        }

        return await res.json();
    } catch (error) {
        console.error('Error fetching all services:', error);
        throw error; // Optionally re-throw the error if you want to handle it further up the call chain
    }
};



//All services Api's 


// Login 
export const loginWithPhoneNumberApi = async (data) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/login.php?mobile=${data.phone}&device=Web`, {
            method: "POST",
        });

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};



export const otpVerifiedApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/verify_login.php?mobile=${data?.loginData.phone}&otpmanual=${data?.loginData.otp}&device=Web`,
            {
                method: "POST",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};
// Login 

const getAuthorizationHeader = () => {
    const username = 626533;
    const password = "a02cadfe7b8fd4f2f878c8d7552331df";;
    return 'Basic ' + btoa(username + ':' + password);
};

const handleApiResponse = async (response) => {
    if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
    }
    return await response.json();
};

export const todayHoroscopePredictionApi = async (data) => {
    console.warn(data?.timezone); // Optional: Remove in production if not needed

    try {
        const res = await fetch(`https://json.astrologyapi.com/v1/sun_sign_prediction/daily/${data?.horosName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(),
                'Accept-Language': 'hi', // Set language to Hindi
            },
            body: JSON.stringify({ timezone: data?.timezone }) // Include timezone if needed
        });

        return await handleApiResponse(res);
    } catch (error) {
        console.error('Error fetching today\'s horoscope prediction:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const tomorrowHoroscopePredictionApi = async (data) => {
    console.log(data)
    try {
        const res = await fetch(`https://json.astrologyapi.com/v1/sun_sign_prediction/daily/next/${data?.horosName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'hi', // Set language to Hindi,
                'Authorization': getAuthorizationHeader(),
            },
            body: JSON.stringify({ timezone: data?.timezone }),
        });

        return await handleApiResponse(res);
    } catch (error) {
        console.error('Error fetching tomorrow\'s horoscope prediction:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const monthlyHoroscopePredictionApi = async (data) => {
    try {
        const res = await fetch(`https://json.astrologyapi.com/v1/horoscope_prediction/monthly/${data?.horosName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'hi', // Set language to Hindi,
                'Authorization': getAuthorizationHeader(),
            },
            body: JSON.stringify({ timezone: data?.timezone }),
        });

        return await handleApiResponse(res);
    } catch (error) {
        console.error('Error fetching monthly horoscope prediction:', error);
        return { error: 'Failed to fetch data' };
    }
};


// join as astrologer api start
export const joinasAstrologer = async (data) => {

    try {
       

        const url = `https://healfate.com/custom_api/join-as-astrologer.php?countrycode=${data?.countrycode}&title=${data?.title}&name=${data?.Name}&email=${data?.email}&mobile=${data?.mobile}&day=${data?.day}&month=${data?.month}&year=${data?.year}&qualification=${data?.qualification}&expertise=${data?.expertise}&experience=${data?.experience}&experiencemonth=${data?.experiencemonth}&language=${data?.language}&state=${data?.state}&gender=${data?.gender}&photo=${data?.photo}&resume=${data?.resume}`;
         console.warn(url); // Optional: Remove in production if not needed
        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};
// join as astrologer api end

// terms and condition api start
export const termsConditionApi = async () => {

    try {
        const res = await fetch('https://user.healfate.com/custom_api/fetchterms', {
            method: "GET",
            headers: {
                'apiKey': '84c290c58df7f8844e2e45d071457f28',
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};
// term and condition api end

//privacy policy api start
export const privacyPolicyApi = async () => {

    try {
        const res = await fetch('https://user.healfate.com/custom_api/fetchprivacy', {
            method: "GET",
            headers: {
                'apiKey': '84c290c58df7f8844e2e45d071457f28',
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};
//privacy policy api end


// pricing policy api start
export const pricingPolicyApi = async () => {

    try {
        const res = await fetch('https://user.healfate.com/custom_api/fetchprivacy', {
            method: "GET",
            headers: {
                'apiKey': '84c290c58df7f8844e2e45d071457f28',
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};
// pricing policy api end


// Utility function for making API calls
const today = new Date();
const defaultData = {
    day: today.getDate(),
    month: today.getMonth() + 1, // Months are zero-based in JavaScript
    year: today.getFullYear(),
    hour: today.getHours(),
    min: today.getMinutes(),
    lat: 25.7464,
    lon: 82.6837,
    tzone: 5.5
};

const isValidNumber = (value) => !isNaN(value) && isFinite(value);

const validateData = (data) => {
    return (
        isValidNumber(data.day) &&
        isValidNumber(data.month) &&
        isValidNumber(data.year) &&
        isValidNumber(data.hour) &&
        isValidNumber(data.min) &&
        isValidNumber(data.lat) &&
        isValidNumber(data.lon) &&
        isValidNumber(data.tzone)
    );
};

const makeApiCall = async (url, data = {}) => {
    const finalData = {
        day: Number(data.day) || defaultData.day,
        month: Number(data.month) || defaultData.month,
        year: Number(data.year) || defaultData.year,
        hour: Number(data.hour) || defaultData.hour,
        min: Number(data.min) || defaultData.min,
        lat: Number(data.lat) || defaultData.lat,
        lon: Number(data.lon) || defaultData.lon,
        tzone: Number(data.tzone) || defaultData.tzone,
    };

    if (!validateData(finalData)) {
        console.error('Invalid data:', finalData);
        return { error: 'Invalid data format' };
    }

    console.log('Data to be sent:', finalData);

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'en',
                'Authorization': getAuthorizationHeader(),
            },
            body: JSON.stringify(finalData),
        });

        return await handleApiResponse(res);
    } catch (error) {
        console.error('Error fetching data:', error);
        return { error: error.message || 'Failed to fetch data' };
    }
};



// All Panchang API functions
export const getBasicPanchang = (data) => makeApiCall('https://json.astrologyapi.com/v1/basic_panchang', data);
export const getBasicPanchangSunrise = (data) => makeApiCall('https://json.astrologyapi.com/v1/basic_panchang/sunrise', data);
export const getAdvancedPanchang = (data) => makeApiCall('https://json.astrologyapi.com/v1/advanced_panchang', data);
export const getAdvancedPanchangSunrise = (data) => makeApiCall('https://json.astrologyapi.com/v1/advanced_panchang/sunrise', data);
export const getPlanetPanchang = (data) => makeApiCall('https://json.astrologyapi.com/v1/advanced_panchang/sunrise', data);
export const getPlanetPanchangSunrise = (data) => makeApiCall('https://json.astrologyapi.com/v1/planet_panchang/sunrise', data);
export const getChaughadiyaMuhurta = (data) => makeApiCall('https://json.astrologyapi.com/v1/chaughadiya_muhurta', data);
export const panchangLagnaTable = (data) => makeApiCall('https://json.astrologyapi.com/v1/panchang_lagna_table', data);
export const monthlyPanchang = (data) => makeApiCall('https://json.astrologyapi.com/v1/monthly_panchang', data);


// All Panchang Api 


export const sliderApi = async () => {
    try {
        // Attempt to fetch data from the API
        const res = await fetch('https://user.healfate.com/custom_api/fetch_banner', {
            method: 'GET',
            headers: {
                'apiKey': '84c290c58df7f8844e2e45d071457f28',
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is not successful (e.g., HTTP status code 4xx or 5xx)
        if (!res.ok) {
            throw new Error('Network response was not ok');
        }

        // Parse the response body as JSON and return it
        return await res.json();
    } catch (error) {
        // If an error occurs during the fetch or parsing
        console.error('There was a problem with the fetch operation:', error);
        throw error; // Throw the error further to handle it elsewhere if needed
    }
};





export const getAstro_details = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/astro_details', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const sadhesati_life_details = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/astro_details', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const sadhesati_Into_details = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/sadhesati_remedies', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const sadhesati_Current_details = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/sadhesati_current_status', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


// Remedies
export const rudraksha_suggestion = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/rudraksha_suggestion', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const Gemstones = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/basic_gem_suggestion', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const mantra = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/numero_fav_mantra', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const yantra = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/ayanamsha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const kalsarpa_details = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/kalsarpa_details', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const kalsarpa_remedy = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/kalsarpa_remedy', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const manglik = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/manglik', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const manglik_remedy = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/manglik_remedy', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const kp_birth_chart = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/kp_birth_chart', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};



export const panchang_lagna_table = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/panchang_lagna_table', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};



export const match_planet_details = async (data) => {
    const sendFinalData = {


    
            m_day: Number(data?.day),
            m_month: Number(data?.month),
            m_year: Number(data?.year),
            m_hour: Number(data?.hours),
            m_min: Number(data?.minutes),
            m_lat: Number(data?.latitude),
            m_lon:  Number(data?.longitude),
            m_tzone: 5.5,
            f_day: Number(data?.day),
            f_month: Number(data?.month),
            f_year: Number(data?.year),
            f_hour: Number(data?.hours),
            f_min: Number(data?.minutes),
            f_lat:Number(data?.latitude),
            f_lon:   Number(data?.longitude),
            f_tzone: 5.5
    
       
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/match_planet_details', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const current_vdasha_all = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/current_vdasha_all', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const sarvashtak = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/sarvashtak', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};



export const general_ascendant_report = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: 5.5
    };
    
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch('https://json.astrologyapi.com/v1/general_ascendant_report', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};
// Remedies

// Ensure handleApiResponse is defined and implemented
// function handleApiResponse(response) {
//     return response.json(); // Adjust based on API response structure
// }


// Define or adjust handleApiResponse if necessary
// function handleApiResponse(response) {
//     return response.json(); // Adjust based on API response structure
// }

export const sadhesati_report = async (data) => {
    const params = new URLSearchParams();
    params.append('d', '2024-07-18');
    params.append('t', '02:02:00');
    params.append('lat', '40.7128');
    params.append('lon', '-74.0060');
    params.append('tz', '5.5');
    params.append('userid', 'healfatejs');
    params.append('authcode', 'c90a3824649b845172634faf36742dc5');


    try {
        const response = await fetch(`https://api.kundli.click/cust_healfatejs_v0.4/sadesati`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: params.toString()
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


