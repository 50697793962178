import moment from 'moment';

export const FormDataApi = async (data) => {
    const formData = new FormData();

    // Convert the JSON data to FormData
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
    });

    try {
        // Send the FormData
        const res = await fetch(`https://healfate.com/custom_api/join-as-astrologer.php`, {
            method: "POST",
            // headers:'multipart/form-data',
            body: formData,
        });

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        return await res.json();
    } catch (error) {
       
        throw error; // Re-throw the error after logging it, or handle it accordingly
    }
};



export const getPlaceOfBirth = async (city) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/fetch_placeOfbirth_API.php?search=${city}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error('Network response was not ok: ${res.statusText');
        }

        return await res.json();
    } catch (error) {
       
        throw error;
    }
};

export const fetchAstroCategory = async () => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/astrologerAPIs/fetch_astro_category.php`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        return await res.json();
    } catch (error) {
        throw error; // Re-throw the error after logging it, or handle it accordingly
    }
};

export const IntakeFormApi = async (data) => {

    try {
        const queryParams = new URLSearchParams({
            name: data?.name || '',
            gender: data?.gender || '',
            dob: data?.dob ?data?.dob: `${data?.day}-${data?.month}-${data?.year}`,
            birthtime: data?.birthtime? data?.birthtime:`${data?.hour}:${data?.minute}:${data?.second}`,
            placeofbirth: data?.PlaceOfBirth,
            userid: data?.userId || '',
            astroid: data?.astrologerId || '',
            lat: data?.latitude || '26.3595',  // Use provided latitude or default
            lng: data?.longitude || '75.9270'  // Use provided longitude or default
        });

        const res = await fetch(
            `https://healfate.com/custom_api/intakeform.php?${queryParams.toString()}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        return await res.json();
    } catch (error) {
        throw error; // Re-throw the error after logging it, or handle it accordingly
    }
};







export const fetchWaitListRequestSend = async (userId) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/waitListAPIs/get_waiting.php?userId=${userId}`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        return await res.json();
    } catch (error) {
        throw error; // Re-throw the error after logging it, or handle it accordingly
    }
};

export const LiveChatPostApi = async (data) => {
    try {
        // Create a FormData object and append the data
        const formData = new FormData();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                formData.append(key, data[key]);
            }
        }

        const res = await fetch("https://healfate.com/custom_api/chat-history.php", {
            method: "POST",
            body: formData, // Use the FormData object as the body
        });

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        const jsonResponse = await res.json();
        return jsonResponse;
    } catch (error) {
        return { success: false, message: "An error occurred while processing the request." }; // Return a default error response
    }
};

export const fetchAllWaitingList = async (userId) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/waitListAPIs/get_waiting.php?userId=${userId}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching the waiting list." }; // Return a default error response
    }
};


export const LiveChatAssistantPostApi = async (data) => {
    try {
        // Create a FormData object and append the data
        const formData = new FormData();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                formData.append(key, data[key]);
            }
        }

        const res = await fetch(`https://healfate.com/custom_api/AssistantChatAPIs/assistant_send_chat.php`, {
            method: "POST",
            body: formData, // Use the FormData object as the body
        });

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        const jsonResponse = await res.json();
        return jsonResponse;
    } catch (error) {
        return { success: false, message: "An error occurred while processing the request." }; // Return a default error response
    }
};


export const LiveChatHistoryInsertApi = async (data) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/createchathistory.php`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while processing the request." }; // Return a default error response
    }
};


export const LiveChatUpdateBalanceApi = async (data) => {
    try {
        const res = await fetch("https://healfate.com/custom_api/updatewalletduringchat.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        const responseData = await res.json();

        // Handle specific error cases from the server response if needed
        if (responseData.error) {
            throw new Error(responseData.error);
        }

        return responseData;
    } catch (error) {
        throw error; // Propagate the error for handling elsewhere if needed
    }
};


export const getLiveChatApi = async (chatid) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/get_chat_history.php`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({ chatid: chatid }), // Wrap chatid in an object
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching chat history." }; // Return a default error response
    }
};


export const getLiveChatAssistantApi = async (chatid) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/AssistantChatAPIs/get_assistant_chat_history.php`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({ chatid: chatid }), // Wrap chatid in an object
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching assistant chat history." }; // Return a default error response
    }
};


export const getAllAstrologerChatApi = async (data) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/astrologerAPIs/fetch_astrologers_chat.php?search=${data?.id == undefined ? "" : data?.id
        }&language=${data?.language == undefined ? "" : data?.language}&expertise=${data?.experties == undefined ? "" : data?.experties
        }&rating=${data?.rating == undefined ? "" : data?.rating}&pricing=${data?.pricing == undefined ? "" : data?.pricing
        }&category=${data?.category == undefined ? "" : data?.category}&experience=${data?.experience == undefined ? "" : data?.experience
        }`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching astrologer chats." }; // Return a default error response
    }
};


export const getAllAstrologerCallApi = async (data) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/astrologerAPIs/fetch_astrologers_call.php?search=${data?.id == undefined ? "" : data?.id
        }&language=${data?.language == undefined ? "" : data?.language}&expertise=${data?.experties == undefined ? "" : data?.experties
        }&rating=${data?.rating == undefined ? "" : data?.rating}&pricing=${data?.pricing == undefined ? "" : data?.pricing
        }&category=${data?.category == undefined ? "" : data?.category}&experience=${data?.experience == undefined ? "" : data?.experience
        }`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching astrologer calls." }; // Return a default error response
    }
};


export const getAllAstrologerVideoCallApi = async (data) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/astrologerAPIs/fetch_astrologers_videochat.php?search=${data?.id == undefined ? "" : data?.id
        }&language=${data?.language == undefined ? "" : data?.language}&expertise=${data?.experties == undefined ? "" : data?.experties
        }&rating=${data?.rating == undefined ? "" : data?.rating}&pricing=${data?.pricing == undefined ? "" : data?.pricing
        }&category=${data?.category == undefined ? "" : data?.category}&experience=${data?.experience == undefined ? "" : data?.experience
        }`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching astrologer video calls." }; // Return a default error response
    }
};


export const PostAllGiftApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/add-gift.php?userid=${data.userid}&astroid=${data.astroid}&giftamount=${data.amount}`,
            {
                method: "POST", // Use uppercase "POST" for the method
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { error: error.message }; // Return error message
    }
};


export const getAllGiftApi = async () => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/gift_plan.php`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching gift plans." }; // Return a default error response
    }
};

export const getAllBlockAstrologers = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/block_astrologers.php?userid=${data.userid}&astroid=${data.astrologerid}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching blocked astrologers." }; // Return a default error response
    }
};



export const BlockAstrologers = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/block_astrologers.php?userid=${data.userid}&astroid=${data.astroId}&message=${data.message}`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while blocking the astrologer." }; // Return a default error response
    }
};


// hero slider banner
export const SliderHerobanner = async () => {
    try {
        const res = await fetch(`https://user.healfate.com/custom_api/fetch-slider`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "apikey": "84c290c58df7f8844e2e45d071457f28", // Replace with your actual API key
            },
        });

        if (!res.ok) {
            throw new Error(`Network response was not ok: ${res.statusText}`);
        }

        const data = await res.json();
        return data;
    } catch (error) {
        console.error("An error occurred:", error);
        return { success: false, message: "An error occurred while fetching the hero banner." }; // Return a default error response
    }
};

// hero slider banner end


// hero slider banner
export const SliderHerobannerMobile = async () => {
    try {
        const res = await fetch(`https://user.healfate.com/custom_api/fetch-slider`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "apikey": "84c290c58df7f8844e2e45d071457f28", // Replace with your actual API key
            },
        });

        if (!res.ok) {
            throw new Error(`Network response was not ok: ${res.statusText}`);
        }

        const data = await res.json();
        return data;
    } catch (error) {
        console.error("An error occurred:", error);
        return { success: false, message: "An error occurred while fetching the hero banner." }; // Return a default error response
    }
};

// hero slider banner end



// Testimonials Start
export const TestimonialsApi = async () => {
    try {
        const res = await fetch(`https://user.healfate.com/custom_api/fetch_testimonial`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "apikey": "84c290c58df7f8844e2e45d071457f28", // Replace with your actual API key
            },
        });

        if (!res.ok) {
            throw new Error(`Network response was not ok: ${res.statusText}`);
        }

        const data = await res.json();
        return data;
    } catch (error) {
        console.error("An error occurred:", error);
        return { success: false, message: "An error occurred while fetching the hero banner." }; // Return a default error response
    }
};
// Testimonials end



// Healingservice Start
export const HealingserviceApi = async () => {
    try {
        const res = await fetch(`https://user.healfate.com/custom_api/fetch_healingexpertise`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "apikey": "84c290c58df7f8844e2e45d071457f28", // Replace with your actual API key
            },
        });

        if (!res.ok) {
            throw new Error(`Network response was not ok: ${res.statusText}`);
        }

        const data = await res.json();
        return data;
    } catch (error) {
        console.error("An error occurred:", error);
        return { success: false, message: "An error occurred while fetching the hero banner." }; // Return a default error response
    }
};
// Healingservice end




// astroservice Start
export const astroserviceApi = async () => {
    try {
        const res = await fetch(`https://user.healfate.com/custom_api/fetch_astrologyexpertise`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "apikey": "84c290c58df7f8844e2e45d071457f28", // Replace with your actual API key
            },
        });

        if (!res.ok) {
            throw new Error(`Network response was not ok: ${res.statusText}`);
        }

        const data = await res.json();
        return data;
    } catch (error) {
        console.error("An error occurred:", error);
        return { success: false, message: "An error occurred while fetching the hero banner." }; // Return a default error response
    }
};
// astroservice end















export const unblockAstrologers = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/unblock_astrologer.php?userid=${data.userid}&astroid=${data.astroId}`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while unblocking the astrologer." }; // Return a default error response
    }
};


export const getSingleAstrologerApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/astrologerAPIs/fetch_astrologer_profile.php?astroId=${data?.id || data?.astroId || data?.mainIId}&userId=${data?.userid || data.userId}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching the astrologer's profile." }; // Return a default error response
    }
};


export const getSingleAstrologerLiveApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/astrologerAPIs/fetch_live_astro?astro=${data}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching the astrologer's profile." }; // Return a default error response
    }
};



export const fetchAstrologerReviewApi = async (astrologerId) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/astrologerAPIs/fetch_astrologer_review.php?astroId=${astrologerId}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching the astrologer's reviews." }; // Return a default error response
    }
};







export const astrologerExpertiseApis = async () => {
    try {
        const res = await fetch(`https://healfate.com/astrologer_app_api/astro_expertise.php`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching astrologer expertise." }; // Return a default error response
    }
};


export const astrologerLanguageApis = async () => {
    try {
        const res = await fetch(`https://healfate.com/astrologer_app_api/astro_language.php`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching astrologer languages." }; // Return a default error response
    }
};

//

export const AfterChatReviewApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/chatAPIs/chat_review.php?astroId=${data.astroId}&userId=${data.useridh}&rating=${data.rating}&review=${data.review}&serviceType=${data.type}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while submitting the chat review." }; // Return a default error response
    }
};







export const filterAstrologerApi = async (data) => {
    try {
        const apiUrl = `https://healfate.com/custom_api/astrologerAPIs/fetch_astrologers_chat.php?search=${data?.id == undefined ? "" : data?.id
        }&language=${data?.language == undefined ? "" : data?.language}&expertise=${data?.experties == undefined ? "" : data?.experties
        }&rating=${data?.rating == undefined ? "" : data?.rating}&pricing=${data?.pricing == undefined ? "" : data?.pricing
        }&category=${data?.category == undefined ? "" : data?.category}&experience=${data?.experience == undefined ? "" : data?.experience
        }`;

        const res = await fetch(`${apiUrl}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching filtered astrologers." }; // Return a default error response
    }
};



export const filterAstrologerAudioApi = async (data) => {
    try {
        const apiUrl = `https://healfate.com/custom_api/astrologerAPIs/fetch_astrologers_call.php?search=${data?.id == undefined ? "" : data?.id
        }&language=${data?.language == undefined ? "" : data?.language}&expertise=${data?.experties == undefined ? "" : data?.experties
        }&rating=${data?.rating == undefined ? "" : data?.rating}&pricing=${data?.pricing == undefined ? "" : data?.pricing
        }&category=${data?.category == undefined ? "" : data?.category}&experience=${data?.experience == undefined ? "" : data?.experience
        }`;

        const res = await fetch(`${apiUrl}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching filtered astrologers for audio calls." }; // Return a default error response
    }
};


export const filterAstrologerVideoApi = async (data) => {
    try {
        const apiUrl = `https://healfate.com/custom_api/astrologerAPIs/fetch_astrologers_videochat.php?search=${data?.id == undefined ? "" : data?.id
        }&language=${data?.language == undefined ? "" : data?.language}&expertise=${data?.experties == undefined ? "" : data?.experties
        }&rating=${data?.rating == undefined ? "" : data?.rating}&pricing=${data?.pricing == undefined ? "" : data?.pricing
        }&category=${data?.category == undefined ? "" : data?.category}&experience=${data?.experience == undefined ? "" : data?.experience
        }`;

        const res = await fetch(`${apiUrl}`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching filtered astrologers for video calls." }; // Return a default error response
    }
};


export const searchAstrologerApi = async (data) => {
    try {
        const apiUrl = `https://healfate.com/custom_api/astrologerAPIs/fetch_astrologers_search.php?search=${data}`;

        const res = await fetch(`${apiUrl}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while searching for astrologers." }; // Return a default error response
    }
};


export const getUserDetailsApi = async (id) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/view_profile.php?userid=${id}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while fetching user details." }; // Return a default error response
    }
};

















export const postAstrologerReviewApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/give-rating.php?astroid=${data.astroId}&userid=${data.useridh}&rating=${data.rating
            }&review=${data.review == null || undefined ? "" : data.review}`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while posting the review." }; // Return a default error response
    }
};


//

export const GiftSendApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/add-gift.php?userid=${data.userid}&astroid=${data.astrologer}&giftamount=${data?.amount}`,
            {
                method: "POST",
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while sending the gift." }; // Return a default error response
    }
};


export const chatRequestSend = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/chatAPIs/chat_request.php?userId=${data.userId}&astroId=${data.astroId}&rqst=${data.rqstSend}`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while sending the chat request." }; // Return a default error response
    }
};


export const chatAssistantRequestSend = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AssistantChatAPIs/assistant_rqst.php?userId=${data?.userId}&astroId=${data?.astroId}&rqst=1`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { success: false, message: "An error occurred while sending the chat assistant request." }; // Return a default error response
    }
};













export const chatVideoRequestSend = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AudioVideoAPIs/audio_video_rqst.php?userId=${data.userId}&astroId=${data.astroId}&rqst=${data.rqstSend}&service=2`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};



export const emergencyCallRequestSend = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AudioVideoAPIs/audio_video_rqst.php?userId=${data.userId}&astroId=${data.astroId}&rqst=${data.rqstSend}&service=3`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};

export const emergencyVideoRequestSend = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AudioVideoAPIs/audio_video_rqst.php?userId=${data.userId}&astroId=${data.astroId}&rqst=${data.rqstSend}&service=4`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};










export const chatAudioRequestSend = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AudioVideoAPIs/audio_video_rqst.php?userId=${data.userId}&astroId=${data.astroId}&rqst=${data.rqstSend}&service=1`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


export const waitListRequestSend = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AudioVideoAPIs/audio_video_rqst.php?userId=${data?.userId}&astroId=${data?.astroId}&rqst=${data?.rqstSend}&declined_by=&service=${data?.service}`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


export const waitListRequestCancel = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AudioVideoAPIs/audio_video_rqst.php?userId=${data?.userId}&astroId=${data?.astroId}&rqst=${data?.rqstSend}&declined_by=User&service=${data?.service}`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Server error: ${res.status}`);
        }

        return await res.json();
    } catch (error) {
        throw error;  // Re-throw the error to be caught in CancelRequest
    }
};



export const endChatApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/chatAPIs/chat_request.php?userId=${data.userid}&astroId=${data.astrologerid}&rqst=4`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};







export const endAudioChatApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AudioVideoAPIs/audio_video_rqst.php?userId=${data.userid}&astroId=${data.astrologerid}&rqst=4&service=2`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


export const endVideoChatApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AudioVideoAPIs/audio_video_rqst.php?userId=${data.userid}&astroId=${data.astrologerid}&rqst=4&service=1`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


export const declinedByUserApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/chatAPIs/chat_request.php?userId=${data.userId}&astroId=${data.astroId}&rqst=${data.rqst}&declined_by=User`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


export const declinedVideoByUserApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AudioVideoAPIs/audio_video_rqst.php?userId=${data.userId}&astroId=${data.astroId}&rqst=${data.rqst}&service=2&declined_by=User`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};





export const declinedEmergencyVideoByUserApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AudioVideoAPIs/audio_video_rqst.php?userId=${data.userId}&astroId=${data.astroId}&rqst=${data.rqst}&service=2&declined_by=User`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};

export const declinedAudioByUserApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/AudioVideoAPIs/audio_video_rqst.php?userId=${data?.userId}&astroId=${data?.astroId}&rqst=${data?.rqst}&service=1&declined_by=User`,
            {
                method: "GET",
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
       
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};














export const fetchChatStatusChange = async (reqid) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/chatAPIs/get_new_status_refresh.php?requestId=${reqid}`, {
            method: "GET",
        });

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


export const FollowUnfollowApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/followAPIs/follow_API.php?userid=${data?.userid}&astrologer_id=${data?.astroid}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        throw error; // Propagate error to the caller
    }
};

// Fetch Api 
export const freeKundaliApi = async (id) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/fetch-saved-kundli.php?userid=${id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!res.ok) {
            const errorDetails = await res.text();
            throw new Error(`Network response was not ok: ${errorDetails}`);
        }

        return await res.json();
    } catch (error) {
        throw error; // Propagate error to the caller
    }
};


export const submitKundaliApi = async (data) => {

    // Extracting day, month, and year from data.dob using moment.js
    const dob = moment(data.dob);
    const day = dob.date();
    const month = dob.month() + 1; // month() returns month from 0 to 11, so adding 1
    const year = dob.year();

    // Extracting hour, minute, and second from data.birthtime using moment.js
    const birthtime = moment(data.birthtime, "HH:mm:ss");
    const hour = birthtime.hour();
    const minute = birthtime.minute();
    const second = birthtime.second();

    try {
        const res = await fetch(
            `https://healfate.com/custom_api/save_kundli.php?Name=${data?.name}&Gender=${data?.gender}&placeofbirthcity=${data?.placeofbirth}&PlaceofBirth=4&userid=${data?.userId}&astroid=${data?.astrologerId}&lat=26.3595&lng=75.9270&day=${data?.day}&month=${data?.month}&year=${data?.year}&hour=${data?.hour}&minute=${data?.minute}&second=1`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        return await res.json();
    } catch (error) {
        throw error; // Re-throw the error after logging it, or handle it accordingly
    }
};
// Fetch Api 





export const alreadyReviewApi = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/astrologerAPIs/fetch_astro_rating_already.php?astroId=${data?.astroid}&userid=${data?.userid}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error("Network response was not ok");
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


export const loginWithPhoneNumberApi = async (data) => {
    try {
        const res = await fetch(`https://user.healfate.com/custom_api/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "apikey": "84c290c58df7f8844e2e45d071457f28", // Replace with your actual API key
            },
            body: JSON.stringify(data), // Include the data in the request body
        });

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


export const otpVerifiedApi = async (data) => {
    try {
        // Construct the URL
        const url = `https://user.healfate.com/custom_api/verify_login`;

        // Prepare the form data
        const formData = new URLSearchParams({
            mobile: data?.loginData.phone,
            otpmanual: data?.loginData.otp,
            device: 'Webfix'
        });

        // Make the POST request
        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData.toString()
        });

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


export const weeklyScheduleGetApi = async (astroid) => {
    try {
        const res = await fetch(`https://healfate.com/astrologer_app_api/fetch_weekly_schedule.php?astroId=${astroid}`, {
            method: "POST",
        });

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


export const fetchAllSimillarAstrologers = async (astrologerId) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/astrologerAPIs/fetch_similar_astrologers.php?astrologer_id=${astrologerId}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


export const firstChatFree = async (data) => {
    try {
        const res = await fetch(
            `https://healfate.com/custom_api/chatAPIs/firstchat.php?userid=${data?.userId}&astroId=${data?.astroId}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};


// export const tokenGenerateApi = async (data) => {

//     const res = await fetch(`https://healfate.com/custom_api/agoraAPIs/generate_token.php?userId=${data?.userId}&astroId=${data?.astroId}`, {
//         method: "Get",
//         headers: {
//             "Content-type": "application/json"
//         },

//     });
//     return await res.json();
// }



// https://healfate.com/custom_api/astrologerAPIs/fetch_live_astrologers.php

export const fetchAllLiveAstrologers = async (reqid) => {
    try {
        const res = await fetch(`https://healfate.com/custom_api/astrologerAPIs/fetch_live_astrologers.php`, {
            method: "GET",
        });

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
     
        return { error: "Failed to fetch data" }; // Return a default error response
    }
};
