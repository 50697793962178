import React, { useEffect, useState } from 'react';
import { privacyPolicyApi } from '../data/AllapiData';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Privacypolicy = () => {
  const [terms, setTerms] = useState('');
  const [error, setError] = useState(null);

  async function fetchPrivacy() {
    const res = await privacyPolicyApi();
    console.log(res); // Log the response
    if (res.error) {
      setError(res.error);
    } else {
      setTerms(res.data); // Assuming the terms are in res.data
    }
  }

  useEffect(() => {
    fetchPrivacy();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
      <Header/>
      <div dangerouslySetInnerHTML={{ __html: terms }} />
  <Footer/>
    </div>
  );
};

export default Privacypolicy;
