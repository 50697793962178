import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Generateticket.css';

const Generateticket = () => {
  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}
      {/* Generateticket section start */}
      <section className="generateticket_section">
        <div className="container">
            <di className="row justify-content-center">
                {/* col start */}
                <div className="col-lg-12">
                    <div className="flex_generating_tkt">
                        <h3>New Ticket</h3>
                        <Link to="/ticket">My Ticket</Link>
                    </div>
                </div>
                {/* col end */}

                {/* col start */}
                <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                    <div className="generating_form_div">
                   <form className="row" method="POST"> 
  <div className="col-md-12 mt-1">
    <label htmlFor="name" className="form-label">Name <span style={{color: 'red'}}>*</span></label> 
    <div className="input-group">  
      <input type="text" className="form-control" name="name" placeholder="Name" required />
    </div>
  </div> 
  <div className="col-md-12 mt-1">
    <label htmlFor="email" className="form-label">Email <span style={{color: 'red'}}>*</span></label> 
    <div className="input-group">  
      <input type="email" className="form-control" name="email"  placeholder="Email" required />
    </div>
  </div> 
  <div className="col-md-12 mt-1">
    <label htmlFor="mobile" className="form-label">Mobile No. <span style={{color: 'red'}}>*</span></label> 
    <div className="input-group">  
      <input type="text" className="form-control" name="mobile"  maxLength={10} oninput="this.value = this.value.replace(/[^0-9.+]/g, '').replace(/(\..?)\../g, '$1');" placeholder="Mobile No." required />
    </div>
  </div> 
  <div className="col-md-12 mt-1">
    <label htmlFor="subject" className="form-label">Subject <span style={{color: 'red'}}>*</span></label> 
    <div className="input-group">  
      <select className="form-control" name="subject">
        <option selected disabled style={{color: 'green'}}>Select your issue</option>
        <option value="First Free Session">First Free Session</option>
        <option value="Too much wait time">Too much wait time</option>
        <option value="Unhappy with the free session">Unhappy with the free session</option>
        <option value="Missed the session">Missed the session</option>
        <option value="Payment Related">Payment Related</option>
        <option value="Previous Order">Previous Order</option>
        <option value="Astrologer didn't answer">Astrologer didn't answer</option>
        <option value="Got a different prediction">Got a different prediction</option>
        <option value="Remedies Issue">Remedies Issue</option>
        <option value="Technical issue">Technical issue</option> 
        <option value="Offers & Coupons">Offers &amp; Coupons</option>
        <option value="General FAQs">General FAQs</option>
        <option value="Some other issues">Some other issues</option>
      </select>
    </div>
  </div> 
  <div className="col-md-12 mt-1">
    <label htmlFor="Description" className="form-label">Description <span style={{color: 'red'}}>*</span></label> 
    <div className="input-group ">  
      <textarea rows={3} className="w-100" id="Description" name="description" placeholder="Description" required defaultValue={""} /> 
    </div>
  </div> 
  <div className="col-12">
    <div className="generating_btn">
      <button type="submit" name="generate">Create Ticket</button>
    </div>
  </div>
</form>

                    </div>
                </div>
                {/* col end */}
            </di>
        </div>
      </section>
      {/* Generateticket section end */}
      <Footer/>
    </>
  )
}

export default Generateticket
