import CryptoJS from 'crypto-js';


// Example key (replace with your actual key handling logic)
const key = 'a053b372-e26f-4f95-bdb8-4b7929ec2307';

export const encrypt = (data) => {
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext) + '::' + CryptoJS.enc.Base64.stringify(iv);
};

export const decrypt = (data) => {
  try {
    const dataParts = data.split('::');
    const encryptedData = CryptoJS.enc.Base64.parse(dataParts[0]);
    const iv = CryptoJS.enc.Base64.parse(dataParts[1]);
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedData }, CryptoJS.enc.Utf8.parse(key), {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return '';
  }
};




// export const key='a053b372-e26f-4f95-bdb8-4b7929ec2307'