import { LocalUser, RemoteUser, useIsConnected, useJoin, useLocalMicrophoneTrack, useLocalCameraTrack,  usePublish, useRemoteUsers,} from "agora-rtc-react";
import React, { useEffect, useState } from "react";
import './liveSession.css'
import { GrPrevious } from "react-icons/gr";
import { CgMenuRound } from "react-icons/cg";
import { GrNext } from "react-icons/gr";
import AstrologersSimpleCards from "../AstrologersSimpleCards/AstrologersSimpleCards";

import { FaGift } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import GiftPage from "../GiftPage/GiftPage";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAllLiveAstrologers, getSingleAstrologerLiveApi } from "../../AllApi/AllApi";
import LiveSimillarAstrologers from "./LiveSimillarAstrologers";
import Talkmodal from "../../data/Talkmodal";
import ChatForLive from "./ChatForLive";
// import "./styles.css";
export const LiveSession = () => {
const [showDownloadSuggetion, setShowDownloadSuggetion] = useState(false);
const [singleAstrologerLiveSession,setSingleAstrologerLiveSession]=useState([])
const [shouldReload, setShouldReload] = useState(false);
const [newUrl, setNewUrl] = useState('');
const [url,setUrl]=useState();
console.warn(url)
const [calling, setCalling] = useState(false);
const isConnected = useIsConnected(); // Store the user's connection status
//  const appId = "7bfd597c053b4c18be36259da70b0ead"; 
const appId ="7bfd597c053b4c18be36259da70b0ead"; 
const channel=singleAstrologerLiveSession[0]?.channel
const token=singleAstrologerLiveSession[0]?.token
useJoin({appid: appId, channel: channel, token: token ? token : null}, calling);
const [micOn, setMic] = useState(true);
const [cameraOn, setCamera] = useState(true);
// const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
// const { localCameraTrack } = useLocalCameraTrack(cameraOn);
// , localCameraTrack 
usePublish([]);
const remoteUsers = useRemoteUsers(''); 
const [showModal, setShowModal] = useState(false);
const [simillarAstrologerOpenModal, setSimillarAstrologerOpenModal] = useState(false);
const toggleModal = (id) => {
setShowModal(!showModal);
};
const toggleOpenAstrologerModal = (id) => {
setSimillarAstrologerOpenModal(!simillarAstrologerOpenModal);
};
const navigate = useNavigate();
const [currentIndex, setCurrentIndex] = useState(0);
const {types}=useParams()
const replaceSpaceWithDash =types
const  [  values,setValues]= useState();
console.log(values)
async  function singleAstrologerLive(data){
const res=  await getSingleAstrologerLiveApi(data)
setSingleAstrologerLiveSession(res?.data)
}
async function allLiveAstrologers(){
const res=  await fetchAllLiveAstrologers()
if(res?.success==true){
setValues(res?.data)
}
}
useEffect(()=>{
allLiveAstrologers()
},[])
function toggleDownloadSuggetion(objids) {
setShowDownloadSuggetion(!showDownloadSuggetion);
}
useEffect(() => {
if (appId && channel && token) {
setCalling(true);
}
}, [appId, channel, token,values]);
useEffect(()=>{
singleAstrologerLive(replaceSpaceWithDash)
},[replaceSpaceWithDash,values])
const handleNextClick = () => {
const nextValue = values[currentIndex]?.name;
setCurrentIndex((prevIndex) => (prevIndex + 1) % values.length);
if (nextValue) {
const formattedData = nextValue.replace(/\s+/g, "-"); // Replace spaces with hyphens
const url = `/live/${formattedData}#`;
setNewUrl(url);
setShouldReload(true);
}
};
useEffect(() => {
if (shouldReload) {
navigate(newUrl); // Navigate to the new URL
setShouldReload(false);
window.location.reload(); // Reload the page
}
}, [shouldReload, newUrl, navigate]);
const handlePrevClick = () => {
const nextValue = values[currentIndex]?.name;
setCurrentIndex((prevIndex) => (prevIndex - 1) % values.length);
if (nextValue) {
const formattedData = nextValue.replace(/\s+/g, "-"); // Replace spaces with hyphens
const url = `/live/${formattedData}`;
setNewUrl(url);
setShouldReload(true);
}
};
return (
<>
<div className="container">
   <div className="row">
      <div className="col-md-12">
         <div className="room mt-4">
            {/* // highlight-next-line */}
            <div className="container">
               <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-md-2 d-sm-none d-xs-none d-none d-lg-block d-xl-block" onClick={handlePrevClick}>
                     <div className="d-flex align-items-center justify-content-center prevIconLiveSession nextIconLiveSession">
                        <GrPrevious size={20}/>
                     </div>
                  </div>
                  <div className="col-md-8">
                     <div className="container">
                        <div className="row  p-0" style={{border:"1px solid #804c35"}}>
                        <div className={`col-md-6 col-sm-12 col-xs-12 col-12 ${remoteUsers.length === 0 ? 'bg-black' : ''} m-0 p-0`}>
                        <div className="user-list">
                           <div className="position-absolute zIndexAfterLiveSessionStarted top-3 bottom-2 right-1">
                              <div className="d-flex align-items-center justify-content-center">
                                 <button className="text-white mb-3" onClick={toggleModal} >
                                    <FaGift size={20}/>
                                 </button>
                              </div>
                              <div className="d-flex align-items-center justify-content-center">
                                 <button className="text-white  mb-3" onClick={toggleDownloadSuggetion}>
                                    <IoCall size={22} />
                                 </button>
                              </div>
                              <div className="d-flex align-items-center justify-content-center">
                                 <button className="text-white  mb-3"  onClick={toggleOpenAstrologerModal} >
                                    <CgMenuRound size={25}/>
                                 </button>
                              </div>
                              <div>
                                 <div className="live_session_rate_rounded d-flex align-items-center justify-content-center">
                                    {singleAstrologerLiveSession[0]?.rate != singleAstrologerLiveSession[0]?.disc_rate ? 
                                    <div>
                                       <p className="text-white live_session_rate_rounded_text text-center">₹ {singleAstrologerLiveSession[0]?.rate}/min</p>
                                       <p  className="text-white live_session_rate_rounded_text text-center cutlinethrough_livesession">₹ {singleAstrologerLiveSession[0]?.disc_rate}/min</p>
                                    </div>
                                    :
                                    <div>
                                       <p className="text-white live_session_rate_rounded_text text-center">₹ {singleAstrologerLiveSession[0]?.rate}/min</p>
                                    </div>
                                    }
                                 </div>
                              </div>
                           </div>
                           {showModal && (
                           <GiftPage
                              id={singleAstrologerLiveSession[0]?.id}
                              closeModal={toggleModal}
                              />
                           )}
                           {simillarAstrologerOpenModal && (
                           <LiveSimillarAstrologers
                              id={singleAstrologerLiveSession[0]?.id}
                              closeModal={toggleOpenAstrologerModal}
                              />
                           )}
                            {showDownloadSuggetion && (
                           <Talkmodal
                              id={singleAstrologerLiveSession[0]?.id}
                              closeModal={toggleDownloadSuggetion}
                              />
                           )}
                           {simillarAstrologerOpenModal && (
                           <LiveSimillarAstrologers
                              id={singleAstrologerLiveSession[0]?.id}
                              closeModal={toggleOpenAstrologerModal}
                              />
                           )}
                           {remoteUsers.length === 0 ? (
                           <div className="user d-flex align-items-center justify-content-center ">
                              <div className="d-flex align-items-center justify-content-center ">
                                 <div className="text-white ">
                                    <div className="d-flex align-items-center justify-content-center my-1">
                                       <img height={30} width={30} src="https://ufcgymweb.azureedge.net/ufcgym-web/home-workouts/membership/Icon_Livestream.png"></img>
                                    </div >
                                    Live session End.
                                 </div>
                              </div>
                           </div>
                           ) : (
                           remoteUsers.slice(0, 1).map((user) => (
                           <div className="user" key={user?.uid}>
                              <div className="d-flex align-items-center justify-content-center" style={{height:"409px"}}>
                                 <RemoteUser cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg" user={user}>
                                    {/* <samp className="user-name">{user.uid}</samp> */}
                                 </RemoteUser>
                              </div>
                           </div>
                           ))
                           )}
                        </div>
                     </div>
                     <div className="col-md-6  col-sm-12 col-xs-12 col-12 d-sm-none d-xs-none d-none d-lg-block d-xl-block">
                        <ChatForLive userCount={remoteUsers.length}/>
                     </div>
                     <div className="col-md-6  col-sm-12 col-xs-12 col-12 d-sm-block d-xs-block d-block d-lg-none d-xl-none">
                        <div className="row p-2 " style={{ flex: "0 1 auto" }}>
                        <div className="col-12 d-flex align-items-center ">
                           <form  className='d-flex gap-1' style={{ width: "100%" }}>
                           <div className='d-flex w-100 px-2' style={{ border: "1px solid #804c35", borderRadius: "10px", position: "relative" }}>
                           <input
                           type="text"
                           autoComplete="off"
                           className="msg styles outline-none border-0 flex-grow-1"
                           placeholder="Type a message..."
                           name='message'
                           // value={messageData.message}
                           // onChange={changeMessageData}
                           style={{ width: "100%", fontSize: "12px", padding: "5px" }}
                           />
                           <label htmlFor="file-input" style={{ display: "flex", alignItems: "center", padding: "0 5px", cursor: "pointer" }}>
                           <i className='bx bxs-image fs-4' style={{ color: "#804c35" }}></i>
                           </label>
                           <input
                           id="file-input"
                           type="file"
                           style={{ display: "none" }}
                           // onChange={changeMessageData}
                           name='image'
                           />
                           <button type="submit"  style={{ border: "none", background: "none", padding: "0 5px"}}>
                           <img
                           src={require("../assets/img/send_msg.png")}
                           alt="Send"
                           style={{ height: "18px", width: "18px" }}
                           />
                           </button>
                        </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="col-md-2  d-sm-none d-xs-none d-none d-lg-block d-xl-block" onClick={handleNextClick}>
         <div className="d-flex align-items-center justify-content-center prevIconLiveSession">
            <GrNext size={20}/>
         </div>
      </div>
   </div>
</div>
</div>
{isConnected && (
<div className="control">
   <div className="left-control">
      <button className="btn" onClick={() => setMic(a => !a)}>
      <i className={`i-microphone ${!micOn ? "off" : ""}`} />
      </button>
      <button className="btn" onClick={() => setCamera(a => !a)}>
      <i className={`i-camera ${!cameraOn ? "off" : ""}`} />
      </button>
   </div>
   <button
   className={`btn btn-phone ${calling ? "btn-phone-active" : ""}`}
   onClick={() => setCalling(a => !a)}
   >
   {calling ? <i className="i-phone-hangup" /> : <i className="i-mdi-phone" />}
   </button>
</div>
)}
</div>
</div>
</div>


<AstrologersSimpleCards/>
</>
);
};
export default LiveSession;