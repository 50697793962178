import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import { sliderrigtside } from '../data/Owlcarouseloptions';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/PaymentDetails.css';

const PaymentDetails = () => {
  return (
    <>
      {/* ========= */}
  <Header/>
    {/* ====== */}
       {/* wallet section start */}
       <section className="wallet_section">
        <div className="container">
            {/* row start */}
            <div className="row">



               {/*=====================col start right side col end 9 =============================*/}
               <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                 <div className="payment_details_main_div">
                    <form action="">
                   <h1>Payment Details</h1>
                   {/* table div payment  */}
                   <div className="payment_dt_flx_div">

                     {/* flx item div */}
                     <div class="payment_flex_itmes">
                        <h3>Total Amount</h3>
                        <h4>&#8377; 25.00</h4>
                    </div>
                     {/* flx item div end */}

                      {/* flx item div */}
                      <div class="payment_flex_itmes">
                        <h3>Total Amount</h3>
                        <h4>&#8377; 25.00</h4>
                    </div>
                     {/* flx item div end */}


                      {/* flx item div */}
                      <div class="payment_flex_itmes">
                        <h3>Total Amount</h3>
                        <h4>&#8377; 25.00</h4>
                    </div>
                     {/* flx item div end */}


                      {/* flx item div */}
                      <div class="payment_flex_itmes">
                        <h3>Total Amount</h3>
                        <h4>&#8377; 25.00</h4>
                    </div>
                     {/* flx item div end */}


                      {/* flx item div */}
                      <div class="payment_flex_itmes">
                        <h3>Total Amount</h3>
                        <h4>&#8377; 25.00</h4>
                    </div>
                     {/* flx item div end */}

                   </div>

<div className="payment_pay_btn_div">
    <Link to="">Pay Now !</Link>
</div>

                   {/* table div payment end */}
                   </form>
                 </div>
               </div>
               {/*=====================col start right side col end 9 =============================*/}


{/* ===================col start right side 3 col========================  */}
<div className="col-lg-3 col-md-3 col-sm-12 col-12">
  <div className="right_side_main_div_sticky mb-2">

    {/* slider start */}
    <div class="sidebar_main_div_sld">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/sld01.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  {/* slider start */}
  <div class="sidebar_main_div_sld2">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/gplay.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/apple01.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  
  {/* app div */}
  <div className="app_download">
    <h2>TalkndHeal on Mobile</h2>
    <Link to="">Download Now</Link>
  </div>
  {/*  */}
  {/* left icons start */}
  <div className="left_icons_so">
    <Link target="_blank" to=""><img src="../images/android.png" alt="Android App" /></Link>
    <Link target="_blank" to=""><img src="../images/apple.png" alt="iOS App" /></Link>
    <Link target="_blank" to=""><img src="../images/fb.png" alt="Facebook" /></Link>
    <Link target="_blank" to=""><img src="../images/insta.png" alt="Instagram" /></Link>
    <Link target="_blank" to=""><img src="../images/twitter.png" alt="Twitter" /></Link>
    <Link target="_blank" to=""><img src="../images/linkedin.png" alt="LinkedIn" /></Link>
    <Link target="_blank" to=""><img src="../images/yt.png" alt="Youtube" /></Link>
  </div>
  {/* left icons end */}

 
  </div>
</div>
{/* ===================col end right side 3 col========================  */}


               </div>
            {/* row end */}
        </div>
      </section>
      {/* wallet section end */}
<Footer/>
    </>
  )
}

export default PaymentDetails
