import React, {useContext, useEffect, useState } from 'react'
import Header2 from '../Header2'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Cookies from "js-cookie";
import MyContext from "../../Context/MyContext";
// import { cookieDomain, domainName } from "../../Url/Url";
import { astrologerExpertiseApis, astrologerLanguageApis, fetchAstroCategory, fetchChatStatusChange, filterAstrologerApi, filterAstrologerAudioApi, filterAstrologerVideoApi, searchAstrologerApi } from '../../AllApi/AllApi'
import Footer from '../Footer';
import { cookieDomain } from '../Url/Url';
import NotFound from '../NotFound/NotFound';

const AstrologerChatCard = () => {
  const { types, idChy } = useParams();
  const [categoriesItem, setCategoriesItem] = useState();
  const [allDrops, setAlldrops] = useState(false);

  const [showChatWaiting, setShowChatWaiting] = useState(false);
  const handleChatWaitingClose = () => setShowChatWaiting(false);
  const handleChatWaitingShow = () => setShowChatWaiting(true);

  const [checkboxes, setCheckboxes] = useState({ value: idChy });
  const [checkboxes2, setCheckboxes2] = useState({ value: idChy });
  console.log(checkboxes)
  useEffect(() => {
    if (idChy) {
      setCheckboxes((prev) => ({
        ...prev,
        [idChy]: true,
      }));
      setCheckboxes2((prev) => ({
        ...prev,
        [idChy]: true,
      }));
    }
  }, [idChy]);
  console.error(checkboxes2)
  const [experienceCheckbox, setExperienceCheckbox] = useState({});
 

  const [expertiesCheckbox, setExpertiesCheckbox] = useState({});



  const [ratingCheckbox, setRatingCheckbox] = useState({});
  const [pricingCheckbox, setPricingCheckbox] = useState({});

  async function categoriesItemFun(id) {
    if (id) {
      const datSend = {
        pricing: pricingCheckbox.value,
        experience: experienceCheckbox.value,
        rating: ratingCheckbox.value,
        language: checkboxes.value,
        language:checkboxes2.value,
        experties: expertiesCheckbox?.value,
        category: id,
        
      };
      const res = await filterAstrologerApi(datSend);
      if (res.success == true) {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res?.data);
        setAstrologerDataVideo(res?.data);
      } else {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res?.data);
        setAstrologerDataVideo(res?.data);
      }
      // Perform additional actions here
    }
  }

  useEffect(() => {
    categoriesItemFun(categoriesItem);
  }, [categoriesItem]);



  const [astrologerLanguageApi, setAstrologerLanguageApi] = useState([]);

  const [astrologerExpertiesApi, setAstrologerExpertiesApi] = useState([]);
  const [astrologerData, setAstrologerData] = useState([]);

  const [astrologerDataAudio, setAstrologerDataAudio] = useState([]);

  const [astrologerDataVideo, setAstrologerDataVideo] = useState([]);
  const [lessBalanceModel, setLessBalanceModel] = useState(false);

  const cookieAstrologerId = Cookies.get("astrologerId");
  let initialAstrologerData = {};

  if (cookieAstrologerId) {
    try {
      initialAstrologerData = JSON.parse(cookieAstrologerId);
    } catch (e) {}
  }

  const [singleAstrologerDataShow, setSingleAstrologerDataShow] = useState(
    initialAstrologerData
  );
  const [url, setUrl] = useState();
  // req send

  const [requestUniqueData, setRequestUniqueData] = useState(
    () => parseInt(Cookies.get("requestUniqueData")) || 0
  );

  const [selectedForm, setSelectedForm] = useState(
    types === undefined
      ? "Chat"
      : types === "video"
      ? "Video"
      : types === "call"
      ? "Call"
      : "Chat"
  );

  useEffect(() => {
    if (types === undefined) {
        navigate('/chat');
    }
}, [types]);

useEffect(() => {
  if (types === 'service' || types === 'Service') {
      navigate('/service/Tarot');
  }
}, [types]);

  // req send
  const [loading, setLoading] = useState(true);

  const contextData = useContext(MyContext);
  const userloginStatus =
    contextData?.userDetails?.loginstatus == "undefined"
      ? 0
      : contextData?.userDetails?.loginstatus;
  const userwalletDetails = contextData?.userDetails?.data?.wallet;
  const MobileFilterGet = contextData?.toggleMobileSearch;
  const userNameDetails = contextData?.userDetails?.data?.name;
  const walletAmount = contextData?.userDetails?.data?.wallet;
  const userProfile = contextData?.userDetails?.data?.profile;
  const profile_url = contextData?.userDetails?.profile_url;
  const openSearchData = contextData?.changeSearch;
  const [showDownloadSuggetion, setShowDownloadSuggetion] = useState(false);
  const [showCallDownloadSuggetion, setShowCallDownloadSuggetion] =
    useState(false);
  const [showVideoDownloadSuggetion, setShowVideoDownloadSuggetion] =
    useState(false);

  const [renderData, setRenderData] = useState(false);
  const { updateData } = useContext(MyContext);


  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const defaultIntakeValue = contextData?.intakeChange; // Set your default value here

  const [changeIntake, setChangeIntake] = useState(0);

  const [showLoginModal, setShowLoginModal] = useState();
  useEffect(() => {
    setChangeIntake(defaultIntakeValue); // Log changeIntake whenever it changes
  }, [defaultIntakeValue]);
  function toggleLoginModal() {
    setShowLoginModal(!showLoginModal);
  }

  const [showIntakeModal, setShowIntakeModal] = useState(false);

  function toggleDownloadSuggetion(objids) {
    setSingleAstrologerDataShow(objids);

    setShowDownloadSuggetion(!showDownloadSuggetion);
  }
  function toggleCallDownloadSuggetion(objids) {
    setSingleAstrologerDataShow(objids);

    setShowCallDownloadSuggetion(!showCallDownloadSuggetion);
  }

  function toggleVideoDownloadSuggetion(objids) {
    setSingleAstrologerDataShow(objids);

    setShowVideoDownloadSuggetion(!showVideoDownloadSuggetion);
  }
  function toggleIntakeModal(objids) {
    setShowIntakeModal(!showIntakeModal);

    if (objids) {
      setSingleAstrologerDataShow(objids);

      const stringifiedObjIds = JSON.stringify(objids);
      Cookies.set("astrologerId", stringifiedObjIds, { domain: cookieDomain });
    }
  }

  const location = useLocation();

  const removeLastSegment = (url) => {
    const urlParts = url.split("/");
    urlParts.pop(); // Remove the last segment
    return urlParts.join("/");
  };

  const resetAll = () => {
    const currentUrl = location.pathname;
    const newUrl = removeLastSegment(currentUrl);
    if (newUrl) {
      navigate(newUrl);
    }
    window.location.reload();
  };



  const navigate = useNavigate();

  async function astrologersShow() {
    const datSend = {
      pricing: pricingCheckbox.value,
      experience: experienceCheckbox.value,
      rating: ratingCheckbox.value,
      language: checkboxes?.value,
      language:checkboxes2.value,
      experties: expertiesCheckbox?.value,
      category: categoriesItem,
    };
    const res = await filterAstrologerApi(datSend);

    if (res.success == true) {
      setUrl(res?.url);
      setAstrologerData(res.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    else if(res.success == false) {
      setUrl(res?.url);
      setAstrologerData(res.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }
  async function astrologersAudioShow() {
    const datSend = {
      pricing: pricingCheckbox.value,
      experience: experienceCheckbox.value,
      rating: ratingCheckbox.value,
      language: checkboxes?.value,
      language:checkboxes2.value,
      experties: expertiesCheckbox?.value,
      category: categoriesItem,
    };
    const res = await filterAstrologerAudioApi(datSend);

    if (res?.success == true) {
      setUrl(res?.url);
      setAstrologerDataAudio(res?.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    else if(res.success == false) {
      setUrl(res?.url);
      setAstrologerDataAudio(res.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }
  async function astrologersVideoShow() {
    const datSend = {
      pricing: pricingCheckbox.value,
      experience: experienceCheckbox.value,
      rating: ratingCheckbox.value,
      language: checkboxes?.value,
      language:checkboxes2.value,
      experties: expertiesCheckbox?.value,
      category: categoriesItem,
    };
    const res = await filterAstrologerVideoApi(datSend);

    if (res.success == true) {
      setUrl(res?.url);
      setAstrologerDataVideo(res.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    else if(res.success == false) {
      setUrl(res?.url);
      setAstrologerDataVideo(res.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (selectedForm === "Chat") {
        await astrologersShow();
      } else if (selectedForm === "Call") {
        await astrologersAudioShow();
      } else if (selectedForm === "Video") {
        await astrologersVideoShow();
      }
    };

    fetchData();
  }, [
    selectedForm,
    checkboxes,
    expertiesCheckbox,
    ratingCheckbox,
    checkboxes2,
    idChy,types,
    experienceCheckbox,
    pricingCheckbox,
  ]);

  const [showChatRequestModal, setShowChatRequestModal] = useState(false);
  const [showVideoRequestModal, setShowVideoRequestModal] = useState(false);
  const [showVideoIntakeModal, setShowVideoIntakeModal] = useState(false);
  // Chat request work start

  // Audio
  const [showAudioRequestModal, setShowAudioRequestModal] = useState(false);
  const [showAudioIntakeModal, setShowAudioIntakeModal] = useState(false);


  const toggleVideoIntakeModal = (objids) => {
    setShowVideoIntakeModal(!showVideoIntakeModal);
    if (objids) {
      setSingleAstrologerDataShow(objids);
      // reqVideoSend(objids?.id);
      const stringifiedObjIds = JSON.stringify(objids);
      Cookies.set("astrologerId", stringifiedObjIds, { domain: cookieDomain });
    }
  };

  function toggleAudioIntakeModal(objids) {
    setShowAudioIntakeModal(!showAudioIntakeModal);
    if (objids) {
      setSingleAstrologerDataShow(objids);
      const stringifiedObjIds = JSON.stringify(objids);
      Cookies.set("astrologerId", stringifiedObjIds, { domain: cookieDomain });
    }
  }
  // refress status api
  function openLessBalanceModel(objids) {
    if (objids) {
      setSingleAstrologerDataShow(objids);
    }
    setLessBalanceModel(true);
  }
  function closeLessBalanceModel() {
    setLessBalanceModel(false);
  }
 

  const [searchCriteria2, setSearchCriteria2] = useState("");

  //  Filter states

  async function getAstrologerLanguage() {
    const res = await astrologerLanguageApis();
    if (res.success == true) {
      setAstrologerLanguageApi(res.data);
    }
  }

  async function getAstrologerExperties() {
    const res = await astrologerExpertiseApis();
    if (res.success == true) {
      setAstrologerExpertiesApi(res.data);
    }
  }

  useEffect(() => {
    getAstrologerLanguage();
    getAstrologerExperties();
  }, []);

  const handleCheckboxChange = async (e) => {
    const { name, checked, value } = e.target;

    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: checked,
      value
    }));

    if (checked) {
      const datSend = {
        pricing: pricingCheckbox.value,
        experience: experienceCheckbox.value,
        rating: ratingCheckbox.value,
        language: value,
        experties: expertiesCheckbox.value,
        category: categoriesItem,
      };

      try {
        let res;
        if (types === "Chat" || types === "chat") {
          navigate(`chat/${value}`);
          res = await filterAstrologerApi(datSend);
          if (res.success) {
            setAstrologerData(res.data);
          }
        } else if (types === "call") {
          navigate(`call/${value}`);
          res = await filterAstrologerAudioApi(datSend);
          if (res.success) {
            setAstrologerDataAudio(res.data);
          }
        } else if (types === "video") {
          navigate(`video/${value}`);
          res = await filterAstrologerVideoApi(datSend);
          if (res.success) {
            setAstrologerDataVideo(res.data);
          }
        }
      } catch (error) {}
      // window.location.reload()
    }
  };
  const handleCheckboxChange2 = async (e) => {
    const { name, checked, value } = e.target;
    setCheckboxes2((prevratingCheckboxes) => ({
      ...prevratingCheckboxes,
      [name]: checked,
      value,
    }));

  

    if (checked) {
      const datSend = {
        pricing: pricingCheckbox.value,
        experience: experienceCheckbox.value,
        rating: ratingCheckbox.value,
        language: value,
        experties: expertiesCheckbox.value,
        category: categoriesItem,
      };

      try {
        let res;
        if (types === "Chat" || types === "chat") {
          navigate(`chat/${value}`);
          res = await filterAstrologerApi(datSend);
          if (res.success) {
            setAstrologerData(res.data);
          }
        } else if (types === "call") {
          navigate(`call/${value}`);
          res = await filterAstrologerAudioApi(datSend);
          if (res.success) {
            setAstrologerDataAudio(res.data);
          }
        } else if (types === "video") {
          navigate(`video/${value}`);
          res = await filterAstrologerVideoApi(datSend);
          if (res.success) {
            setAstrologerDataVideo(res.data);
          }
        }
      } catch (error) {}
      // window.location.reload()
    }
  };

  // search Filtered

  const [refressStatus, setRefressStatus] = useState({});

  async function fetchStatusApi(id) {
    try {
      const res = await fetchChatStatusChange(id);
      if (res.success) {
        // Check if the new data is different from the current data
        if (JSON.stringify(res) !== JSON.stringify(refressStatus)) {
          setRefressStatus(res);
          updateData(res); // Assuming updateData is defined elsewhere
        }
      } else {
        // Handle API error or failure
      }
    } catch (error) {
      // Handle fetch error
    }
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const normalizedTypes = types ? capitalizeFirstLetter(types) : "Chat";


  // Use useEffect to trigger the intake change when changeIntake updates
  const handleExpCheckboxChange = async (e) => {
    const { name, checked, value } = e.target;
    setExpertiesCheckbox((prevratingCheckboxes) => ({
      ...prevratingCheckboxes,
      [name]: checked,
      value,
    }));

   
  
    const datSend = {
      pricing: pricingCheckbox.value,
      experience: experienceCheckbox.value,
      rating: ratingCheckbox.value,
      language: checkboxes.value,
      language: checkboxes2.value,
      experties: value,
      category: categoriesItem,
    };
  
    try {
      const res = await filterAstrologerApi(datSend);
      if (res.success) {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res.data);
        setAstrologerDataVideo(res.data);
      } else {
        // Handle error or empty response
        console.error('Error filtering astrologers:', res.error);
        setAstrologerData([]);
        setAstrologerDataAudio([]);
        setAstrologerDataVideo([]);
      }
    } catch (error) {
      console.error('Error filtering astrologers:', error);
      // Handle error state
      setAstrologerData([]);
      setAstrologerDataAudio([]);
      setAstrologerDataVideo([]);
    }
  };
  

  const handleRatingCheckboxChange = async (e) => {
    const { name, checked, value } = e.target;

    setRatingCheckbox((prevratingCheckboxes) => ({
      ...prevratingCheckboxes,
      [name]: checked,
      value,
    }));

    // Perform any action based on the checked status
    if (checked) {
      const datSend = {
        pricing: pricingCheckbox.value,
        experience: experienceCheckbox.value,
        rating: value,
        language: checkboxes.value,
        language:checkboxes2.value,
        experties: expertiesCheckbox?.value,
        category: categoriesItem,
      };
      const res = await filterAstrologerApi(datSend);
      if (res.success == true) {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res?.data);
        setAstrologerDataVideo(res?.data);
      } else if (res.success == false) {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res?.data);
        setAstrologerDataVideo(res?.data);
      } else {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res?.data);
        setAstrologerDataVideo(res?.data);
      }
    }
  };

  // Low To High filter

  const handlePricingCheckboxChange = async (e) => {
    const { name, checked, value } = e.target;

    setPricingCheckbox((prevratingCheckboxes) => ({
      ...prevratingCheckboxes,
      [name]: checked,
      value,
    }));
    // window?.location?.reload()

    // Perform any action based on the checked status
    if (checked) {
      const datSend = {
        pricing: value,
        rating: ratingCheckbox.value,
        experience:experienceCheckbox.value,
        language: checkboxes.value,
        language:checkboxes2.value,
        experties: expertiesCheckbox?.value,
        category: categoriesItem,
      };
      const res = await filterAstrologerApi(datSend);
      if (res.success == true) {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res?.data);
        setAstrologerDataVideo(res?.data);
      } else if (res.success == false) {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res?.data);
        setAstrologerDataVideo(res?.data);
      } else {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res?.data);
        setAstrologerDataVideo(res?.data);
      }
      // Perform additional actions here
    }
  };

  const handleExperienceCheckboxChange = async (e) => {
    const { name, checked, value } = e.target;

    setExperienceCheckbox((prevratingCheckboxes) => ({
      ...prevratingCheckboxes,
      [name]: checked,
      value,
    }));
    // window?.location?.reload()

    // Perform any action based on the checked status
    if (checked) {
      const datSend = {
        pricing: pricingCheckbox.value,
        rating: ratingCheckbox.value,
        language: checkboxes.value,
        language:checkboxes2.value,
        experties: expertiesCheckbox?.value,
        category: categoriesItem,
        experience: value,
      };
      const res = await filterAstrologerApi(datSend);
      if (res.success == true) {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res?.data);
        setAstrologerDataVideo(res?.data);
      } else if (res.success == false) {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res?.data);
        setAstrologerDataVideo(res?.data);
      } else {
        setAstrologerData(res.data);
        setAstrologerDataAudio(res?.data);
        setAstrologerDataVideo(res?.data);
      }
      // Perform additional actions here
    }
  };

  // Low To high Filter

  const handleSearchInputChange2 = (event) => {
    setSearchCriteria2(event?.target?.value);
  };


  useEffect(() => {
    const savingId = requestUniqueData;

    const fetchAndUpdateStatus = async () => {
      await fetchStatusApi(savingId);
    };

    fetchAndUpdateStatus(); // Initial fetch

    const intervalId = setInterval(fetchAndUpdateStatus, 3000); // Fetch every 3 seconds

    return () => {
      clearInterval(intervalId); // Cleanup on unmount
    };
  }, [requestUniqueData]); // Include the id in the dependency array

  // chat 2


  // Function to handle form selection
  const handleFormSelection = (formName) => {
    setSelectedForm(formName);

    // navigate(formName)
  };
  

  // chat 2

  useEffect(() => {}, [
    renderData,
    pricingCheckbox,
    ratingCheckbox,
    experienceCheckbox,
    expertiesCheckbox,
    checkboxes,
    checkboxes2
  ]);

  async function fetchFilteredAstrologers() {
    try {
      const response = await searchAstrologerApi(searchCriteria2);
      if (response.success == true) {
        setAstrologerData(response?.data);
        setAstrologerDataAudio(response?.data);
        setAstrologerDataVideo(response?.data);
      }
      if (response.success == false) {
        setAstrologerData(response?.data);
        setAstrologerDataAudio(response?.data);
        setAstrologerDataVideo(response?.data);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (searchCriteria2 != "") {
      fetchFilteredAstrologers();
    } else {
      astrologersShow();
      astrologersVideoShow();
      astrologersAudioShow();
    }
  }, [searchCriteria2]);

  // All functionality Varta

  // for category of header
  const [allAstroData, setAllAstroData] = useState([]);

  async function FetchAstroData() {
    try {
      const data = await fetchAstroCategory();
      if (data?.message === "Data Found") {
        setAllAstroData(data?.data);
      } else {
      }
    } catch (error) {}
  }

  useEffect(() => {
    FetchAstroData();
  }, []);

  const [activeItem, setActiveItem] = useState("All");

  const handleItemClick = (item) => {
    setActiveItem(item === activeItem ? "All" : item);
    setCategoriesItem(item);
  };

  // for category of header

  // useEffect(()=>{
  //   const datSend={id:searchCriteria,pricing:pricingCheckbox.value
  //     ,rating:ratingCheckbox.value,language:value,experties:expertiesCheckbox.value}
  //  const res =    filterAstrologerApi(datSend)
  //  const res2 =    filterAstrologerAudioApi(datSend)
  //  const res3 =    filterAstrologerVideoApi(datSend)

  // },[categoriesItem])


  return (
    <>
       {/* ========= */}
       <Header2/>
       {/* ====== */}

       {/* astrologer caht card section start */}

<section className="astrologer_chatcard_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* =================================col start left ==========================================*/}
      <div className="col-lg-2 col-md-12 col-sm-12 col-12">
        {/* left filter box start */}
        <div className="filter_box_main_div">
          {/* filter head start */}
          <div className="filter_head_div">
            <h3>Filter By</h3>
            <Link to="">Reset All <i class='fa fa-refresh'></i></Link>
          </div>
          {/* filter head end */}

{/* accordian div start */}
<div className="accordian_main_div">
<div class="accordion" id="accordionExample">

    {/* accodion items start */}
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Languages</button> 
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      {/* nested  row start  */}
      <div className="row">

        {/* nested col start */}
        {astrologerLanguageApi?.map(({ name }) => (
<div className="col-lg-6 col-md-6 col-sm-12 col-12" key={name}>
  <div className="languages_main_div">
  <div className="form-check">
<input className="form-check-input" name="language1" type="radio" value={name} checked={idChy == name || checkboxes2[name] || false } onChange={handleCheckboxChange2} id={`l100-${name}`}/>
<label className="form-check-label" htmlFor={`l100-${name}`}>
{name}
</label>
</div>
</div>
</div>
   ))}
{/* nested col end */}

     

      </div>
      {/* nested  row end */}
      </div>
    </div>
  </div>
    {/* accodion items end */}

    {/* accodion items start */}
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Expertise</button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
         {/* nested  row start  */}
         
      <div className="row">

{/* nested col start */}
{astrologerExpertiesApi?.map((ele) => (
<div className="col-lg-6 col-md-6 col-sm-12 col-12" key={ele.name}>
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="expertise1"  type="radio" value={ele.name} checked={expertiesCheckbox.value == ele.name} onChange={handleExpCheckboxChange} id={`l1-${ele.name}`}/>
        <label className="form-check-label" htmlFor={`l1-${ele.name}`}>
          {ele.name && ele.name.length > 7 ? `${ele.name.slice(0, 9)}..` : ele.name}
        </label>
</div>
</div>
</div>
))}
{/* nested col end */}

</div>
{/* nested  row end */}
      </div>
    </div>
  </div>
  {/* accodion items end */}

   {/* accodion items start */}
   <div class="accordion-item">
    <h2 class="accordion-header" id="heading3">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3"> Experience</button>
    </h2>
    <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
      <div class="accordion-body">
         {/* nested  row start  */}
      <div className="row">

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" value="HighToLow" type="radio"                                  name="experience" defaultValueid="c111" onChange={handleExperienceCheckboxChange} />
     <label className="form-check-label" htmlFor="c111">High To Low</label>
</div>
</div>
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" type="radio" name="experience" value="LowToHigh" defaultValueid="c122"
  onChange={handleExperienceCheckboxChange}/>
  <label className="form-check-label" htmlFor="c122">Low To High</label>
</div>
</div>
</div>
{/* nested col end */}
</div>
{/* nested  row end */}
      </div>
    </div>
  </div>
  {/* accodion items end */}

  
   {/* accodion items start */}
   <div class="accordion-item">
    <h2 class="accordion-header" id="heading4">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4"> Price</button>
    </h2>
    <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
      <div class="accordion-body">
         {/* nested  row start  */}
      <div className="row">

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" value="HighToLow" type="radio" name="pricing"defaultValue
  id="c11" onChange={handlePricingCheckboxChange} />
   <label className="form-check-label" htmlFor="c11" >High To Low</label>
</div>
</div>
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" type="radio" name="pricing" value="LowToHigh" defaultValueid="c12"
onChange={handlePricingCheckboxChange}/>
 <label  className="form-check-label" htmlFor="c12"> Low To High</label>
</div>
</div>
</div>
{/* nested col end */}
</div>
{/* nested  row end */}
      </div>
    </div>
  </div>
  {/* accodion items end */}

  
   {/* accodion items start */}
   <div class="accordion-item">
    <h2 class="accordion-header" id="heading5">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5"> Ratings</button>
    </h2>
    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
      <div class="accordion-body">
         {/* nested  row start  */}
      <div className="row">

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="rating" value="1" checked={ratingCheckbox[1]}
  onChange={handleRatingCheckboxChange} type="radio" defaultValue id="c14"/>
<label className="form-check-label"htmlFor="c14">
  <i className="fa fa-star" /></label>
</div>
</div>
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="rating" value="2" checked={ratingCheckbox[2]}
  onChange={handleRatingCheckboxChange} type="radio" defaultValue id="c15"/>
<label className="form-check-label"htmlFor="c15">
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  </label>
</div>
</div>
</div>
{/* nested col end */}


{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="rating" value="3" checked={ratingCheckbox[3]}
  onChange={handleRatingCheckboxChange} type="radio" defaultValue id="c16"/>
<label className="form-check-label"htmlFor="c16">
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  </label>
</div>
</div>
</div>
{/* nested col end */}


{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="rating" value="4" checked={ratingCheckbox[4]}
  onChange={handleRatingCheckboxChange} type="radio" defaultValue id="c17"/>
<label className="form-check-label"htmlFor="c17">
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  </label>
</div>
</div>
</div>
{/* nested col end */}


{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="rating" value="5" checked={ratingCheckbox[5]}
  onChange={handleRatingCheckboxChange} type="radio" defaultValue id="c18"/>
<label className="form-check-label"htmlFor="c18">
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  </label>
</div>
</div>
</div>
{/* nested col end */}

</div>
{/* nested  row end */}
      </div>
    </div>
  </div>
  {/* accodion items end */}

</div>
</div>
{/* accordian div end */}

        </div>
        {/* left filter box end */}
      </div>
    {/* =================================col end left ==========================================*/}

    {/* ================================= 9 col start right ==========================================*/}
    <div className="col-lg-10 col-md-12 col-sm-12 col-12">
      {/* right nested row start */}
      <div className="row tabscol">
        {/* right nested col start */}
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
           {/* right nav tabs div main start */}
            <div className="navtbas_astrochat_main_div">
              {/* tabs ul start */}
            <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <Link to={`/chat${idChy !== undefined ? `/${idChy}` : ""}`}>
    <button className={`nav-link w-100 ${selectedForm === "Chat" ? "switch_pills_tabs_text_active" : "switch_pills_tabs_text_inactive"}`} onClick={() => handleFormSelection("Chat")} type="button" role="tab">Chat With Astrologers</button>
    </Link>
  </li>
  <li className="nav-item" role="presentation">
  <Link to={`/call${idChy !== undefined ? `/${idChy}` : ""}`}>
    <button className={`nav-link w-100 ${selectedForm === "Call"? "switch_pills_tabs_text_active": "switch_pills_tabs_text_inactive"}`} onClick={() => handleFormSelection("Call")} type="button" role="tab">Call With Astrologers</button>
    </Link>
  </li>
  <li className="nav-item" role="presentation">
  <Link to={`/video${idChy !== undefined ? `/${idChy}` : ""}`}>
    <button className={`nav-link w-100 ${selectedForm === "Video"? "switch_pills_tabs_text_active": "switch_pills_tabs_text_inactive"}`} onClick={() => handleFormSelection("Video")} type="button" role="tab">Video With Astrologers</button>
    </Link>
  </li>
</ul>
 {/* tabs ul end */}

            </div>
           {/* end right nav yab div */}
        </div>
        {/* right nested col end */}

        {/* right nested col start */}
        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="search_box_rightside">
          <div class="input-group">
  <input type="text" value={searchCriteria2} onChange={handleSearchInputChange2} className="form-control" placeholder="Find Astrologers" aria-label="Recipient's username" aria-describedby="button-addon2"/>
  <button className="btn" type="button" id="button-addon2"><i className='bx bx-search-alt'></i></button>
</div>
          </div>
        </div>
        {/* right nested col end */}
      </div>
      {/* right nested row end */}

      {/* filter section start mobile view  */}
      <div className="filter_main_div">
        <div className="filter_txt_div">
          <h2>Chat With Astrologer</h2>
          <Link to="#filteroffcanvas" data-bs-toggle="offcanvas"  aria-controls="filteroffcanvas" className='fa fa-filter'></Link>
        </div>
      
      </div>
      {/* filter section end mobile view  */}



      {/* div start all tab start */}
   
      <div className="all_tab_nav_div">
      {allAstroData?.map((ele) => (
        <Link  key={ele?.id} className={`${ activeItem === ele?.name ? "active" : "inactive"}`} onClick={() => handleItemClick(ele?.name)}>{ele?.name}</Link> 
        ))}
      </div>
   
      {/* div start all tab end */}

 {/*============= tab content start main============ */}
<div class="tab-content" id="myTabContent">

{/* ===tabpane start chat=== */}
<div class="tab-pane fade show active" role="tabpanel" aria-labelledby="chat-tab">
   {/* tab pan row start desktop astrochat cards*/}
   <div className="row desktop_chatastrologer">
     {/*==============Chat============= col start */}
    {selectedForm == "Chat" && (
<>
{" "}
{astrologerData != null ? (
astrologerData?.map((ele, index) => (
  <>
    <div className="col-lg-3 col-md-4 col-sm-12 col-12">
    <div className="chat_astrologer_main_div">
       <Link to={`/astrologersDetails/${ele?.astrologer_id}`}>
         {/* flx profile astro main div start */}
         <div className="profile_astro_flexdiv">
          {/* profile div */}
          
          <div className="profile_img_box">
          <div className="profile_prarent_div">
          {ele?.profile == "" ? (
            <img src={require("https://healfate.com/astro_docs/No-image-found.jpg")} alt="" className='img-fluid'/>
          ) : (
            <img src={`${url}${ele?.profile}`} alt=""/>
               )}
              {/* overlay div img */}
           <div className="overlay_img_tic">
           <img src={require("../assets/img/verified_red_icon.png")} alt="" className='img-fluid'/>
           </div>
           {/* end overlay */}
          </div>

           <div className="profile_astroname">
           <h4>{ele?.name?.length >= 12 ? `${ele?.name.slice(0,8)}...`: ele?.name} <b> {ele?.busy == 0 &&
ele?.chat_status != 0 ? (<span className='inactive_div'> </span>) : ("")}</b> <b> {ele?.busy == 1 ? (
  <span className='active_div'> </span>) : ("")}</b></h4>
           <div className='rating_profile_div'>
            <span class="text-start">5.0</span>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            </div>
           </div>
           </div>
         
          {/* end profile div */}

  <div className="profileastro_button_div">
    <span>Chat</span>
  </div>

        </div>
        {/* flx profile astro main div end */}
      
             {/* experties div start */}
      <div className="main_div_experties">
        <div className="experties_heading_flex">
          <h4><i className='fa fa-book'></i> Expertise</h4>
        </div>
        <h5>{ele.expertise.length >= 20 ? ele.expertise.slice(0, 20) : ele?.expertise}...</h5>
      </div>
      {/* experties div end */}

       {/* experties div start */}
       <div className="main_div_experties">
        <div className="experties_heading_flex">
          <h4><i className='fa fa-language'></i> Language</h4>
        </div>
        <h5>{ele.language.length >= 20 ? ele.language.slice(0, 20) : ele?.language}...</h5>
      </div>
      {/* experties div end */}

       {/* experties div start */}
       <div className="main_div_experties">
        <div className="experties_heading_flex">
          <h4><i className='fa fa-calendar'></i> Experience</h4>
        </div>
        <h5>{ele?.total_exp} Years</h5>
      </div>
      {/* experties div end */}

      {/* exp price div start */}
      <div className="price_div_exper_chat">
        <span>Free</span>
      </div>
      {/* exp price div end */}

      </Link>
      </div>
    </div>
    </>
))
) : (
<NotFound/>
)}
</>
)}
     {/*==============Chat============= col end */}

      {/*==============Call============= col start */}
    {selectedForm == "Call" && (
<>
{" "}
{astrologerDataAudio != null ? (
astrologerDataAudio?.map((ele, index) => (
  <>
    <div className="col-lg-3 col-md-4 col-sm-12 col-12" key={index}>
    <div className="chat_astrologer_main_div">
       <Link to={`/astrologersDetails/${ele?.astrologer_id}`}>
         {/* flx profile astro main div start */}
         <div className="profile_astro_flexdiv">
          {/* profile div */}
          
          <div className="profile_img_box">
          <div className="profile_prarent_div">
          {ele?.profile == "" ? (
            <img src={require("https://healfate.com/astro_docs/No-image-found.jpg")} alt="" className='img-fluid'/>
          ) : (
            <img src={`${url}${ele?.profile}`} alt=""/>
               )}
              {/* overlay div img */}
           <div className="overlay_img_tic">
           <img src={require("../assets/img/verified_red_icon.png")} alt="" className='img-fluid'/>
           </div>
           {/* end overlay */}
          </div>

           <div className="profile_astroname">
           <h4>{ele?.name?.length >= 12 ? `${ele?.name.slice(0,8)}...`: ele?.name} <b> {ele?.busy == 0 &&
ele?.chat_status != 0 ? (<span className='inactive_div'> </span>) : ("")}</b> <b> {ele?.busy == 1 ? (
  <span className='active_div'> </span>) : ("")}</b></h4>
           <div className='rating_profile_div'>
            <span class="text-start">5.0</span>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            </div>
           </div>
           </div>
         
          {/* end profile div */}

  <div className="profileastro_button_div">
    <span>Call</span>
  </div>

        </div>
        {/* flx profile astro main div end */}
      
             {/* experties div start */}
      <div className="main_div_experties">
        <div className="experties_heading_flex">
          <h4><i className='fa fa-book'></i> Expertise</h4>
        </div>
        <h5>{ele.expertise.length >= 20 ? ele.expertise.slice(0, 20) : ele?.expertise}...</h5>
      </div>
      {/* experties div end */}

       {/* experties div start */}
       <div className="main_div_experties">
        <div className="experties_heading_flex">
          <h4><i className='fa fa-language'></i> Language</h4>
        </div>
        <h5>{ele.language.length >= 20 ? ele.language.slice(0, 20) : ele?.language}...</h5>
      </div>
      {/* experties div end */}

       {/* experties div start */}
       <div className="main_div_experties">
        <div className="experties_heading_flex">
          <h4><i className='fa fa-calendar'></i> Experience</h4>
        </div>
        <h5>{ele?.total_exp} Years</h5>
      </div>
      {/* experties div end */}

      {/* exp price div start */}
      <div className="price_div_exper_chat">
        <span>Free</span>
      </div>
      {/* exp price div end */}

      </Link>
      </div>
    </div>
    </>
))
) : (
<NotFound/>
)}
</>
)}
      {/*==============Call============= col end */}


      
      {/*==============Video============= col start */}
    {selectedForm == "Video" && (
<>
{" "}
{astrologerDataVideo != null ? (
astrologerDataVideo?.map((ele, index) => (
  <>
    <div className="col-lg-3 col-md-4 col-sm-12 col-12" key={index}>
    <div className="chat_astrologer_main_div">
       <Link to={`/astrologersDetails/${ele?.astrologer_id}`}>
         {/* flx profile astro main div start */}
         <div className="profile_astro_flexdiv">
          {/* profile div */}
          
          <div className="profile_img_box">
          <div className="profile_prarent_div">
          {ele?.profile == "" ? (
            <img src={require("https://healfate.com/astro_docs/No-image-found.jpg")} alt="" className='img-fluid'/>
          ) : (
            <img src={`${url}${ele?.profile}`} alt=""/>
               )}
              {/* overlay div img */}
           <div className="overlay_img_tic">
           <img src={require("../assets/img/verified_red_icon.png")} alt="" className='img-fluid'/>
           </div>
           {/* end overlay */}
          </div>

           <div className="profile_astroname">
           <h4>{ele?.name?.length >= 12 ? `${ele?.name.slice(0,8)}...`: ele?.name} <b> {ele?.busy == 0 &&
ele?.chat_status != 0 ? (<span className='inactive_div'> </span>) : ("")}</b> <b> {ele?.busy == 1 ? (
  <span className='active_div'> </span>) : ("")}</b></h4>
           <div className='rating_profile_div'>
            <span class="text-start">5.0</span>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            </div>
           </div>
           </div>
         
          {/* end profile div */}

  <div className="profileastro_button_div">
    <span>Video</span>
  </div>

        </div>
        {/* flx profile astro main div end */}
      
             {/* experties div start */}
      <div className="main_div_experties">
        <div className="experties_heading_flex">
          <h4><i className='fa fa-book'></i> Expertise</h4>
        </div>
        <h5>{ele.expertise.length >= 20 ? ele.expertise.slice(0, 20) : ele?.expertise}...</h5>
      </div>
      {/* experties div end */}

       {/* experties div start */}
       <div className="main_div_experties">
        <div className="experties_heading_flex">
          <h4><i className='fa fa-language'></i> Language</h4>
        </div>
        <h5>{ele.language.length >= 20 ? ele.language.slice(0, 20) : ele?.language}...</h5>
      </div>
      {/* experties div end */}

       {/* experties div start */}
       <div className="main_div_experties">
        <div className="experties_heading_flex">
          <h4><i className='fa fa-calendar'></i> Experience</h4>
        </div>
        <h5>{ele?.total_exp} Years</h5>
      </div>
      {/* experties div end */}

      {/* exp price div start */}
      <div className="price_div_exper_chat">
        <span>Free</span>
      </div>
      {/* exp price div end */}

      </Link>
      </div>
    </div>
    </>
))
) : (
<NotFound/>
)}
</>
)}
      {/*==============Video============= col end */}


   </div>
    {/* tab pan row end desktop astrochat cards*/}

    {/* tab pan row start ========================MOBILE======================== astrochat cards*/}
   <div className="row mobile_chatastrologer">
         {/*==============Chat============= col   col start */}
    {selectedForm == "Chat" && (
<>
{" "}
{astrologerData != null ? (
astrologerData?.map((ele, index) => (
  <>
    <div className="col-lg-3 col-md-4 col-sm-12 col-12">
    <div className="chat_astrologer_main_div_mobile">
       <Link to={`/astrologersDetails/${ele?.astrologer_id}`}>
        {/* profile main div flex start mobile */}
        <div className="mobile_profile_flex_main_div">

          {/* mobile img box start  */}
          <div className="mobile_img_box_profile">
            <div className="mobile_img_parent_div">
            {ele?.profile == "" ? (
            <img src={require("https://healfate.com/astro_docs/No-image-found.jpg")} alt="" className='img-fluid'/>
          ) : (
            <img src={`${url}${ele?.profile}`} alt=""/>
               )}
          {/* profile overlay mobile start */}
          <div className="profile_mobile_overlay_div">
          <img src="../images/tic.png" alt="" className='img-fluid'/>
          </div>
          </div>
          {/* profile overlay mobile end */}

          {/* profile astrochat card names start */}
          <div className="mobile_profile_chat_name">
            <h4>{ele?.name?.length >= 12 ? `${ele?.name.slice(0,8)}...`: ele?.name} <b> {ele?.busy == 0 &&
             ele?.chat_status != 0 ? (<span className='inactive_div'> </span>) : ("")}</b> <b> {ele?.busy == 1 ? (
           <span className='active_div'> </span>) : ("")}</b></h4>
            <div className='mobile_rating_profile_div'>
            <span class="text-start">5.0</span>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            </div>
            <div className="mobile_experties">
              <h5>{ele.expertise.length >= 20 ? ele.expertise.slice(0, 20) : ele?.expertise}...</h5>
              <h6>{ele?.total_exp} Years </h6>
              <h6> {ele.language.length >= 20 ? ele.language.slice(0, 20) : ele?.language}...</h6>
            </div>
          </div>
          {/* profile astrochat card names end */}
          </div>
          {/* mobile img box end */}

          {/* mobile button chat and free start */}
          <div className="mobile_chat_btn">
            <span>Chat</span>
            <b>Free</b>
          </div>
          {/* mobile button chat and free end */}

        </div>
        {/* profile main div flex end mobile*/}
      </Link>
      </div>
    </div>
    </>
))
) : (
<NotFound/>
)}
</>
)}
{/*==============Chat============= col  col end */}

 {/*==============Chat============= col   col start */}
 {selectedForm == "Call" && (
<>
{" "}
{astrologerDataAudio != null ? (
astrologerDataAudio?.map((ele, index) => (
  <>
    <div className="col-lg-3 col-md-4 col-sm-12 col-12">
    <div className="chat_astrologer_main_div_mobile">
       <Link to={`/astrologersDetails/${ele?.astrologer_id}`}>
        {/* profile main div flex start mobile */}
        <div className="mobile_profile_flex_main_div">

          {/* mobile img box start  */}
          <div className="mobile_img_box_profile">
            <div className="mobile_img_parent_div">
            {ele?.profile == "" ? (
            <img src={require("https://healfate.com/astro_docs/No-image-found.jpg")} alt="" className='img-fluid'/>
          ) : (
            <img src={`${url}${ele?.profile}`} alt=""/>
               )}
          {/* profile overlay mobile start */}
          <div className="profile_mobile_overlay_div">
          <img src="../images/tic.png" alt="" className='img-fluid'/>
          </div>
          </div>
          {/* profile overlay mobile end */}

          {/* profile astrochat card names start */}
          <div className="mobile_profile_chat_name">
            <h4>{ele?.name?.length >= 12 ? `${ele?.name.slice(0,8)}...`: ele?.name} <b> {ele?.busy == 0 &&
             ele?.chat_status != 0 ? (<span className='inactive_div'> </span>) : ("")}</b> <b> {ele?.busy == 1 ? (
           <span className='active_div'> </span>) : ("")}</b></h4>
            <div className='mobile_rating_profile_div'>
            <span class="text-start">5.0</span>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            </div>
            <div className="mobile_experties">
              <h5>{ele.expertise.length >= 20 ? ele.expertise.slice(0, 20) : ele?.expertise}...</h5>
              <h6>{ele?.total_exp} Years </h6>
              <h6> {ele.language.length >= 20 ? ele.language.slice(0, 20) : ele?.language}...</h6>
            </div>
          </div>
          {/* profile astrochat card names end */}
          </div>
          {/* mobile img box end */}

          {/* mobile button chat and free start */}
          <div className="mobile_chat_btn">
            <span>Call</span>
            <b>Free</b>
          </div>
          {/* mobile button chat and free end */}

        </div>
        {/* profile main div flex end mobile*/}
      </Link>
      </div>
    </div>
    </>
))
) : (
<NotFound/>
)}
</>
)}
{/*==============Call============= col  col end */}


 {/*==============Video============= col   col start */}
 {selectedForm == "Video" && (
<>
{" "}
{astrologerDataVideo != null ? (
astrologerDataVideo?.map((ele, index) => (
  <>
    <div className="col-lg-3 col-md-4 col-sm-12 col-12">
    <div className="chat_astrologer_main_div_mobile">
       <Link to={`/astrologersDetails/${ele?.astrologer_id}`}>
        {/* profile main div flex start mobile */}
        <div className="mobile_profile_flex_main_div">

          {/* mobile img box start  */}
          <div className="mobile_img_box_profile">
            <div className="mobile_img_parent_div">
            {ele?.profile == "" ? (
            <img src={require("https://healfate.com/astro_docs/No-image-found.jpg")} alt="" className='img-fluid'/>
          ) : (
            <img src={`${url}${ele?.profile}`} alt=""/>
               )}
          {/* profile overlay mobile start */}
          <div className="profile_mobile_overlay_div">
          <img src="../images/tic.png" alt="" className='img-fluid'/>
          </div>
          </div>
          {/* profile overlay mobile end */}

          {/* profile astrochat card names start */}
          <div className="mobile_profile_chat_name">
            <h4>{ele?.name?.length >= 12 ? `${ele?.name.slice(0,8)}...`: ele?.name} <b> {ele?.busy == 0 &&
             ele?.chat_status != 0 ? (<span className='inactive_div'> </span>) : ("")}</b> <b> {ele?.busy == 1 ? (
           <span className='active_div'> </span>) : ("")}</b></h4>
            <div className='mobile_rating_profile_div'>
            <span class="text-start">5.0</span>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            <i class="fa fa-star fontCard"></i>
            </div>
            <div className="mobile_experties">
              <h5>{ele.expertise.length >= 20 ? ele.expertise.slice(0, 20) : ele?.expertise}...</h5>
              <h6>{ele?.total_exp} Years </h6>
              <h6> {ele.language.length >= 20 ? ele.language.slice(0, 20) : ele?.language}...</h6>
            </div>
          </div>
          {/* profile astrochat card names end */}
          </div>
          {/* mobile img box end */}

          {/* mobile button chat and free start */}
          <div className="mobile_chat_btn">
            <span>Video</span>
            <b>Free</b>
          </div>
          {/* mobile button chat and free end */}

        </div>
        {/* profile main div flex end mobile*/}
      </Link>
      </div>
    </div>
    </>
))
) : (
<NotFound/>
)}
</>
)}
{/*==============Video============= col  col end */}

   </div>
    {/* tab pan row end====================== MOBILE======================== astrochat cards*/}

</div>
  {/* ===tabpane end chat=== */}

</div>
{/*============= tab content end main============ */}

    </div>
    {/* =================================col end right ==========================================*/}

    </div>
    {/* row end */}
  </div>
    {/* container end */}
</section>
       {/*  astrologer caht card section end*/}

       
{/* ========================================== FilterSide FilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide Bar Bar STARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTART================================================================================================================================================================================================================================================================= */}

   
<div className="filter_offcanvas_main_div">

<div class="offcanvas offcanvas-end" tabindex="-1" id="filteroffcanvas" aria-labelledby="filteroffcanvasLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="filteroffcanvasLabel">Filter By</h5>
    <Link to="">Reset All <i class='fa fa-refresh'></i></Link>
    <button type="button" className='fa fa-close' data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  {/* body start */}
  <div class="offcanvas-body">
   
{/* accordian div start */}
<div className="accordian_main_div">
<div class="accordion" id="accordionExample">

    {/* accodion items start */}
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOneflt">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneflt" aria-expanded="true" aria-controls="collapseOneflt">Languages</button> 
    </h2>
    <div id="collapseOneflt" class="accordion-collapse collapse show" aria-labelledby="headingOneflt" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      {/* nested  row start  */}
      <div className="row">

      {/* nested col start */}
      {astrologerLanguageApi?.map(({ name }) => (
<div className="col-lg-4 col-md-4 col-sm-4 col-4" key={name}>
  <div className="languages_main_div">
  <div className="form-check">
<input className="form-check-input" name="language1" type="radio" value={name} checked={idChy == name || checkboxes2[name] || false } onChange={handleCheckboxChange2} id={`l100-${name}`}/>
<label className="form-check-label" htmlFor={`l100-${name}`}>
{name}
</label>
</div>
</div>
</div>
   ))}
{/* nested col end */}

      </div>
      {/* nested  row end */}
      </div>
    </div>
  </div>
    {/* accodion items end */}

    {/* accodion items start */}
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwoflt">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoflt" aria-expanded="false" aria-controls="collapseTwoflt"> Expertise</button>
    </h2>
    <div id="collapseTwoflt" class="accordion-collapse collapse" aria-labelledby="headingTwoflt" data-bs-parent="#accordionExample">
      <div class="accordion-body">
         {/* nested  row start  */}
      <div className="row">

{/* nested col start */}
{astrologerExpertiesApi?.map((ele) => (
<div className="col-lg-4 col-md-4 col-sm-4 col-4" key={ele.name}>
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="expertise1"  type="radio" value={ele.name} checked={expertiesCheckbox.value == ele.name} onChange={handleExpCheckboxChange} id={`l1-${ele.name}`}/>
        <label className="form-check-label" htmlFor={`l1-${ele.name}`}>
          {ele.name && ele.name.length > 7 ? `${ele.name.slice(0, 9)}..` : ele.name}
        </label>
</div>
</div>
</div>
))}
{/* nested col end */}

</div>
{/* nested  row end */}
      </div>
    </div>
  </div>
  {/* accodion items end */}

   {/* accodion items start */}
   <div class="accordion-item">
    <h2 class="accordion-header" id="heading3flt">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3flt" aria-expanded="false" aria-controls="collapse3flt"> Experience</button>
    </h2>
    <div id="collapse3flt" class="accordion-collapse collapse" aria-labelledby="heading3flt" data-bs-parent="#accordionExample">
      <div class="accordion-body">
         {/* nested  row start  */}
      <div className="row">


{/* nested col start */}
<div className="col-lg-4 col-md-4 col-sm-4 col-4">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" value="HighToLow" type="radio"                                  name="experience" defaultValueid="c111" onChange={handleExperienceCheckboxChange} />
     <label className="form-check-label" htmlFor="c111">High To Low</label>
</div>
</div>
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-4 col-md-4 col-sm-4 col-4">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" type="radio" name="experience" value="LowToHigh" defaultValueid="c122"
  onChange={handleExperienceCheckboxChange}/>
  <label className="form-check-label" htmlFor="c122">Low To High</label>
</div>
</div>
</div>
{/* nested col end */}
</div>
{/* nested  row end */}
      </div>
    </div>
  </div>
  {/* accodion items end */}

  
   {/* accodion items start */}
   <div class="accordion-item">
    <h2 class="accordion-header" id="heading4flt">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4flt" aria-expanded="false" aria-controls="collapse4flt"> Price</button>
    </h2>
    <div id="collapse4flt" class="accordion-collapse collapse" aria-labelledby="heading4flt" data-bs-parent="#accordionExample">
      <div class="accordion-body">
         {/* nested  row start  */}
      <div className="row">

{/* nested col start */}
<div className="col-lg-4 col-md-4 col-sm-4 col-4">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" value="HighToLow" type="radio" name="pricing"defaultValue
  id="c11" onChange={handlePricingCheckboxChange} />
   <label className="form-check-label" htmlFor="c11" >High To Low</label>
</div>
</div>
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-4 col-md-4 col-sm-4 col-4">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" type="radio" name="pricing" value="LowToHigh" defaultValueid="c12"
onChange={handlePricingCheckboxChange}/>
 <label  className="form-check-label" htmlFor="c12"> Low To High</label>
</div>
</div>
</div>
{/* nested col end */}
</div>
{/* nested  row end */}
      </div>
    </div>
  </div>
  {/* accodion items end */}

  
   {/* accodion items start */}
   <div class="accordion-item">
    <h2 class="accordion-header" id="heading5flt">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5flt" aria-expanded="false" aria-controls="collapse5flt"> Ratings</button>
    </h2>
    <div id="collapse5flt" class="accordion-collapse collapse" aria-labelledby="heading5flt" data-bs-parent="#accordionExample">
      <div class="accordion-body">
         {/* nested  row start  */}
      <div className="row">


{/* nested col start */}
<div className="col-lg-4 col-md-4 col-sm-4 col-4">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="rating" value="1" checked={ratingCheckbox[1]}
  onChange={handleRatingCheckboxChange} type="radio" defaultValue id="c14"/>
<label className="form-check-label"htmlFor="c14">
  <i className="fa fa-star" /></label>
</div>
</div>
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-4 col-md-4 col-sm-4 col-4">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="rating" value="2" checked={ratingCheckbox[2]}
  onChange={handleRatingCheckboxChange} type="radio" defaultValue id="c15"/>
<label className="form-check-label"htmlFor="c15">
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  </label>
</div>
</div>
</div>
{/* nested col end */}


{/* nested col start */}
<div className="col-lg-4 col-md-4 col-sm-4 col-4">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="rating" value="3" checked={ratingCheckbox[3]}
  onChange={handleRatingCheckboxChange} type="radio" defaultValue id="c16"/>
<label className="form-check-label"htmlFor="c16">
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  </label>
</div>
</div>
</div>
{/* nested col end */}


{/* nested col start */}
<div className="col-lg-4 col-md-4 col-sm-4 col-4">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="rating" value="4" checked={ratingCheckbox[4]}
  onChange={handleRatingCheckboxChange} type="radio" defaultValue id="c17"/>
<label className="form-check-label"htmlFor="c17">
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  </label>
</div>
</div>
</div>
{/* nested col end */}


{/* nested col start */}
<div className="col-lg-4 col-md-4 col-sm-4 col-4">
  <div className="languages_main_div">
  <div className="form-check">
  <input className="form-check-input" name="rating" value="5" checked={ratingCheckbox[5]}
  onChange={handleRatingCheckboxChange} type="radio" defaultValue id="c18"/>
<label className="form-check-label"htmlFor="c18">
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  <i className="fa fa-star" />
  </label>
</div>
</div>
</div>
{/* nested col end */}

</div>
{/* nested  row end */}
      </div>
    </div>
  </div>
  {/* accodion items end */}

</div>
</div>
{/* accordian div end */}
  </div>
  {/* body start end*/}

</div>

</div>

{/* ========================================== FilterSide FilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide BarFilterSide Bar Bar ENDENDENDENDENDENDENDENDENDENDENDENDENDENDEND================================================================================================================================================================================================================================================================= */}

         {/* footer componets start */}
      <Footer/>
      {/*footer componets end */}
    </>
  )
}

export default AstrologerChatCard
