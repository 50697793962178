import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Numerologyreport.css';

const Numerologyreport = () => {
  return (
    <>
      {/* ========= */}
  <Header/>
    {/* ====== */}
      {/* lalkitab report section start */}
      <section className="lalkitab_report_section numerology_sec">
        <div className="container">
            {/* lalkitab report title */}
            <div className="lalkitab_rp_tittle">
                <h1>Lal Kitab Report</h1>
            </div>
            {/* lalkitab report title end */}
            {/* row start */}
            <div className="row">
                {/* col start */}
                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                    {/* main div left tabs div start */}
                   <div className="left_div_main_div_tabs">
                   <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {/* Basic Details */}
    <button class="nav-link active" id="v-pills-nm01-tab" data-bs-toggle="pill" data-bs-target="#v-pills-nm01" type="button" role="tab" aria-controls="v-pills-nm01" aria-selected="true">Basic Details    </button>
    {/* Basic Details */}

    {/* Numerology Report */}
    <button class="nav-link" id="v-pills-nm02-tab" data-bs-toggle="pill" data-bs-target="#v-pills-nm02" type="button" role="tab" aria-controls="v-pills-nm02" aria-selected="false">Numerology Report</button>
    {/* Numerology Report */}

    {/* Favourable Time */}
    <button class="nav-link" id="v-pills-nm03-tab" data-bs-toggle="pill" data-bs-target="#v-pills-nm03" type="button" role="tab" aria-controls="v-pills-nm03" aria-selected="false">Favourable Time</button>
    {/* Favourable Time */}

    {/* Favourable Vastu */}
    <button class="nav-link" id="v-pills-nm04-tab" data-bs-toggle="pill" data-bs-target="#v-pills-nm04" type="button" role="tab" aria-controls="v-pills-nm04" aria-selected="false">Favourable Vastu</button>
    {/* Favourable Vastu */}

    
    {/* Fast Timings */}
    <button class="nav-link" id="v-pills-nm05-tab" data-bs-toggle="pill" data-bs-target="#v-pills-nm05" type="button" role="tab" aria-controls="v-pills-nm05" aria-selected="false">Fast Timings </button>
    {/* Fast Timings */}
    
    {/* Favourable God */}
    <button class="nav-link" id="v-pills-nm06-tab" data-bs-toggle="pill" data-bs-target="#v-pills-nm06" type="button" role="tab" aria-controls="v-pills-nm06" aria-selected="false">Favourable God</button>
    {/* Favourable God */}

    {/* Favourable Mantra */}
    <button class="nav-link" id="v-pills-nm07-tab" data-bs-toggle="pill" data-bs-target="#v-pills-nm07" type="button" role="tab" aria-controls="v-pills-nm07" aria-selected="false">Favourable Mantra</button>
    {/* Favourable Mantra */}

    
    {/* Daily Prediction */}
    <button class="nav-link" id="v-pills-nm08-tab" data-bs-toggle="pill" data-bs-target="#v-pills-nm08" type="button" role="tab" aria-controls="v-pills-nm08" aria-selected="false">Daily Prediction</button>
    {/* Daily Prediction */}

  </div>
                   </div>
    {/* main div left tabs div end */}
                </div>
                {/* col end */}

                 {/* col start */}
                 <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                    {/* main div right tabs div start */}
                    <div class="tab-content" id="v-pills-tabContent">
                        {/*==================== Basic Details text tab content start ===================*/}
          <div class="tab-pane fade show active" id="v-pills-nm01" role="tabpanel" aria-labelledby="v-pills-nm01-tab" tabindex="0">

            {/* row start */}
            <div className="row">
                {/* col start */}
               <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                 {/* numerology rp title */}
                 <div className="numerology_rptitlr">
                    <h2>Basic Details </h2>
                    <hr />
                 </div>
                 {/* numerology rp title */}
               </div>
                {/* col end */}

  {/* col start */}
  <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="numerology_number_content_div">
                         <h2>2</h2>
                        <h3>Radical Number</h3>
                        </div>
                     </div>
                     {/* col end */}

                       {/* col start */}
                       <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="numerology_number_content_div">
                        <h2>7</h2>
                         <h3>Destiny Number</h3>
                        </div>
                     </div>
                     {/* col end */}


                       {/* col start */}
                       <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="numerology_number_content_div">
                        <h2>8</h2>
                        <h3>Name Number</h3>
                        </div>
                     </div>
                     {/* col end */}


                       {/* col start */}
                       <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="numerology_number_content_div">
                        <h2>5</h2>
                        <h3>Evil Number</h3>
                        </div>
                     </div>
                     {/* col end */}

                        {/* col start */}
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="numerology_deatils_content_div">
                        <h4>Name</h4>
                        <p>prabhakar saini</p>
                        </div>
                     </div>
                     {/* col end */}

                      {/* col start */}
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="numerology_deatils_content_div">
                        <h4>Date</h4>
                        <p>29-6-2024</p>
                        </div>
                     </div>
                     {/* col end */}

                       {/* col start */}
                       <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="numerology_deatils_content_div">
                        <h4>Favourable Color </h4>
                        <p>White</p>
                        </div>
                     </div>
                     {/* col end */}

                        {/* col start */}
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="numerology_deatils_content_div">
                        <h4>Fav Day</h4>
                        <p>Sunday, Monday, Friday</p>
                        </div>
                     </div>
                     {/* col end */}

            </div>
            {/* row end */}
      
            </div>
       {/* ===================Basic Details text tab content end========================= */}

        {/*==================== Basic Details text tab content start ===================*/}
        <div class="tab-pane fade show active" id="v-pills-nm02" role="tabpanel" aria-labelledby="v-pills-nm02-tab" tabindex="0">

{/* row start */}
<div className="row">
    {/* col start */}
   <div className="col-lg-12 col-md-12 col-sm-12 col-12">
     {/* numerology rp title */}
     <div className="numerology_rptitlr">
        <h2>Numerology Report </h2>
        <hr />
     </div>
     {/* numerology rp title */}
   </div>
    {/* col end */}

    {/* col start */}
    <div className="col-lg-12">
        <div className="numerology_txt_div">
            <h3>What the Number Says About You </h3>
            <p>Your radical number 2. Its ruler is Moon, which makes you an imaginative, art loving and affectionate person. Though your imagination shall be of high order, your physical sturdiness may not be so good. You will be blessed with plenty of intelligence and shrewdness, areas in which you will be ahead to others. Like the constant change in appearance of moon, you will not be firm on one thought or plan. You will have a tendency to change your ideas and to leave one project for another. Patience and enterprise will be lacking. Due to this reason, many of your schemes will not be completed in time. You may have less self-confidence and belief in your acumen. This may lead to despair. You will have high social status, and whomsoever you will own in your mind, will render you results as expected. Amongst people, you will be popular, and will build up your social status by your labour. According to your age, you may have to face ailments pertaining to your eyes, stomach and urinary system. Stress and interrelated problems will also disturb you. Water born diseases like cough, cold, headaches will affect you sometimes.</p>
        </div>
    </div>
    {/* col end */}

</div>
{/* row end */}

</div>
{/* ===================Basic Details text tab content end========================= */}


  </div>
     {/* main div right tabs div end */}
                </div>
                {/* col end */}

            </div>
            {/* row end */}
        </div>
      </section>
      {/* lalkitab report section end */}
<Footer/>
    </>
  )
}

export default Numerologyreport;
