import React, { useState, useEffect, useRef } from 'react';

const SearchComponent = () => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const inputRef = useRef(null);

  const handleSearchIconClick = (e) => {
    e.preventDefault();  // Prevent form submission on click
    setShowSearchInput(!showSearchInput);
  };

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setShowSearchInput(false);
    }
  };

  useEffect(() => {
    if (showSearchInput) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSearchInput]);

  return (
    <div className="input-group" ref={inputRef}>
      {showSearchInput && (
        <input type="text" placeholder="Search..." className="form-control" />
      )}
      <button className="btn" onClick={handleSearchIconClick}>
        {showSearchInput ? <i className='bx bx-x'></i> : <i className='bx bx-search-alt'></i>}
      </button>
    </div>
  );
};

export default SearchComponent;
