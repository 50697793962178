// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCOzjMgDl-lc5pKPxnzsA47QeDIEyzobEI",
  authDomain: "immortal-varta-feb1c.firebaseapp.com",
  projectId: "immortal-varta-feb1c",
  storageBucket: "immortal-varta-feb1c.appspot.com",
  messagingSenderId: "94167470357",
  appId: "1:94167470357:web:de89d82b8e97c6186e591c",
  measurementId: "G-P3EP66KN22"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission);

    if (permission === "granted") {
          
  const token = await getToken(messaging,{
      vapidKey:
      "BKZZ2Xz3LA17ygzP-Vwr1JAeGkepAmLyL_qW6BzHeWc1_5XT6Yu2oecI2p90LFBt7b_74lMR4iXpjQ2awR1mW0I"
   });
   console.log("FCM Token:",token);
}
};
