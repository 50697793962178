import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import {  Chatastrologerdetails, Similartopicslider } from '../data/Owlcarouseloptions';
import { allServicesApi, fetchastrochatData, singleServicesApi } from '../data/AllapiData';
import LoadingPage from './Loading';
import LoadingComponent from '../components/LoadingComponent/LoadingComponent';

import Header from '../components/Header';
import Footer from '../components/Footer';
import MyContext from '../Context/MyContext';
import '../CssFiles/Services.css';

const ServicesPage = () => {
  
  const [loading, setLoading] = useState(true);
const {id}=useParams()
const contextData = useContext(MyContext);
const headerService = contextData?.headerServiceData
// for slice function  
const truncateDescription = (description) => {
  if (!description) return 'Description not available.';
  const words = description.split(' ');
  return words.slice(0, 7).join(' ') + '...';
};
function formatName(str) {
  return str?.replace(/\s+/g, '')?.toUpperCase();
}


const navigate =useNavigate()
// for slice function  


console.log(id)
let formattedId = id.replace(/-/g, ' ');
console.log(formattedId)
  // chat api==============
  const [chatdata,setchatdata] = useState();
  const [chatbaseurl,setchatbaseurl] = useState();
  const [allAstrologerServiceData,setAllAstrologerServiceData]=useState([])
  const [singleAstrologerServiceData,setSingleAstrologerServiceData]=useState({})
  console.log(singleAstrologerServiceData)
  const [astroUrl,setAstroUrl]=useState()
  // console.warn(astroUrl)

 async function allAstrologerFetch(){
    const res= await allServicesApi()
   
    setAllAstrologerServiceData(res?.data)
  }

  function capitalizeFirstLetter(str) {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }


  async function singleAstrologerFetch(data) {
    try {
      // Set loading to true before starting the fetch
      setLoading(true);
  
      // Fetch the data
      const res = await singleServicesApi(data);
  
      // Check if the response was successful
      if (res?.success) {
        setAstroUrl(res?.url);
        setSingleAstrologerServiceData(res?.data);
  
        // Redirect if the names don't match
        if (formatName(res?.data?.name) !== formatName(formattedId)) {
          navigate('/no-page-found');
        }
      } else {
        // Handle unsuccessful response
        console.error('Fetch was not successful:', res?.message);
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Error fetching astrologer data:', error);
    } finally {
      // Set loading to false after fetch is complete, regardless of success or failure
      setLoading(false);
    }
  }
  

  useEffect(()=>{
    singleAstrologerFetch(formattedId)
    allAstrologerFetch()
    const chatFetchdata = async() =>{
  try {
    const res = await fetchastrochatData();
      if(res?.success===true){
      setchatdata(res?.data);
      setchatbaseurl(res?.url);
    }
    
  } catch (error) {
    console.log(error);
  }
    }
    
    chatFetchdata();
  },[id]);




    // not found any page 

  // end chat api


const [Readmore, Setreadmore] = useState(true);
const toggleReadmore = () =>{
    Setreadmore(!Readmore);
}






  return (
    <>
  <Header/>
  {!loading?
   <div>
     {/* ========= */}
     
    {/* ====== */}
      {/* astrology section start */}
      <section className="astrology_section">
        <div className="container">
            {/* astrology titile */}
            <div className="astrology_title_div">
                <h1>{singleAstrologerServiceData?.name}</h1>
                <hr />
            </div>
            {/* astrology titile end */}
            <div className="row">
            <div id="circle-shape-example">
                {/* col start */}
                <div className="col-lg-2 col-4">
                   <div className="astrology_img_box">
               <img src={`${astroUrl}${singleAstrologerServiceData?.image}`} alt="image" className="curve" />
           </div>
           </div>

                {/* col end */}

                {/* col start */}
                <div class="col-lg-12 col-12">
   <div className="astrology_txt_div">
  <p>{singleAstrologerServiceData?.description}</p>

{ Readmore ? null : (
    <>
     <p>{singleAstrologerServiceData?.description2}</p>
   
    </>
)}

  <Link className="moreless-button" onClick={toggleReadmore} to="javascript:void(0);">
  { Readmore ? 'Read More' : 'Read Less'} <span className='fa fa-angle-double-right'></span>
  </Link>
   
  
</div>
</div>

                {/* col end */}
                </div>
            </div>
        </div>
      </section>
      {/* astrology section end */}


{/* ============================== Chat with Astrologers section start ====================================== */}
<section className="chat_with_astrologer_section">
  <div className="container">
    {/* Chat title with astrolger start */}
    <div class="chat_with_astrologer_title">
          <h2>Chat With Astrologer</h2>
          <Link to="/chat">View All</Link>
      </div>
    {/* Chat title with astrolger end */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-12 col-md-12 col-sm-12col-12">
       <div className="chat_withastrologer_main_div">
       <OwlCarousel className="owl-theme" {...Chatastrologerdetails} >

{/* item div start */}

{chatdata?.map((ele) => {
const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};
  return(

<div className="item" key={ele?.id}>
<Link to="">
<div className="chat_withastrologer_media_main_div">
<div className="d-flex">
  <div className="flex-shrink-0">
   <div className="chatastro_img_box">
   {ele.profile==''?  <img src="https://healfate.com/astro_docs/No-image-found.jpg" alt="..." className='img-fluid' /> :
  <img src={`${chatbaseurl}${ele.profile}`} alt="..." className='img-fluid' /> 
  
  }
   </div>
  </div>
  <div className="flex-grow-1 ms-2">
    <div className="chat_astroname_div">
      <h3>{ele.name}</h3>
      {/* chat rating */}
    <div className="chatastro_rating">
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star-o" />
</div>
{/* chat rating */}
<h4>{truncateText (ele?.expertise,20)}</h4>
<h5 class="chatastro_price"><span>{ele?.rate}</span> <strong>{ele?.disc_rate}</strong> </h5>

    </div>
  </div>
</div>
{/* over lay best offer  */}
<div class="chatastro_bestoffer"> Best Offer</div>
{/* over lay best offer  */}
</div>
</Link>
</div>
  )
})}

{/* item div end */}

</OwlCarousel>
       </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>
{/* ============================== Chat with Astrologers section end ====================================== */}

{/* Read Similar Topics section */}
<section className="similar_topic_section">
    <div className="container">
        {/* Similar Topics title */}
          <div className="similar_topic_title">
          <h2>Read Similar Topics</h2>
          <hr />
          </div>
        {/*  Similar Topics title end */}
        <div className="row">
            {/* col start  */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="main_div_similar_topic_slider_div">
                <OwlCarousel className="owl-theme" {...Similartopicslider}>

{/* item div start */}
 {/*  */}

           {allAstrologerServiceData?.map((ele)=>
             <div className="item" key={ele?.id}>
            <Link to={`/services/${ele?.name.replace(/\s+/g, '-')}`}>
              <div className="similar_topic_main_div">
                <div className='similar_topic_img_box'>
                  <img src={`${astroUrl}${ele?.icon}`} alt="" className='img-fluid' />
                </div>
                <h3>{ele?.name}</h3>
                <p className="phone_txt_none">{truncateDescription(ele?.description)}... <span className="readMore">read more</span></p>
              </div>
            </Link>
          </div>
           )
          }
          {/*  */}
          {/*  */}
         
       



</OwlCarousel>
                </div>
            </div>
            {/* col end  */}
        </div>
    </div>
</section>
{/* Read Similar Topics section end */}

</div>:<LoadingComponent/>}
<Footer/>
    </>
  )
}

export default ServicesPage
