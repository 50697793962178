import React from "react";
import { TfiFaceSad } from "react-icons/tfi";
import Header from '../components/Header';

const NotFound = () => {
    return (
        <>
         {/* ========= */}
  <Header/>
    {/* ====== */}
        <div className="container">
            <div className="row d-flex justify-content-center py-4 fs-1">
                <div className="col-md-5 Section_Container">
                    <div className="text-center py-3 d-flex justify-content-center">
                        <TfiFaceSad className="" style={{ fontSize: "100px",color:"#e6af80" }} />
                    </div>
                    <h1 className="text-center ">404</h1>
                    <p className="text-center text-black m-0">Page not found...</p>
                    <p className="text-center">
                        <a href="/" className="fs-5">
                            {" "}
                            Back to the Home
                        </a>
                    </p>
                </div>
            </div>
        </div>
        </>
    );
};

export default NotFound;
