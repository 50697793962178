import React, { useContext, useState } from "react";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyContext from "../../Context/MyContext";




import { cookieDomain, decryptData } from "../Url/Url";
import { loginWithPhoneNumberApi, otpVerifiedApi } from "../../AllApi/AllApi";

const Login = (props) => {
const [loginData, setLoginData] = useState({});
const [otpPageSeen, setOtpPageSeen] = useState(false);
const [otpData, setOtpData] = useState({});

function loginChange(e) {
e.target.value = e.target.value.replace(/\D/, "");
setLoginData({ ...loginData, [e.target.name]: e.target.value });
}

async function otpSubmit(event) {
    event.preventDefault();

    const dataSend = {
        loginData,
        otpData,
    };

    try {
        const res = await otpVerifiedApi(dataSend);
       console.warn(res)
        if (res.success) {
            const expirationDays = 30;
            const encryptedToken = res.token;

            Cookies.set('token', encryptedToken, {
                expires: expirationDays,
                domain: cookieDomain, // Ensure cookieDomain is defined
                secure: true,
                sameSite: 'Lax',
            });

            // Close modal
            if (props?.closeModal) {
                props.closeModal();
            }

            // Show success message
            toast.success("Login successfully!");

            // Redirect with a delay
            setTimeout(() => {
                // window.location.reload();
            }, 5000);
        } else {
            toast.error("Incorrect OTP!", {
                autoClose: 4000,
            });
        }
    } catch (error) {
        // Handle API call errors
        toast.error("An error occurred. Please try again.", {
            autoClose: 4000,
        });
    }
}

function toggleCloseLoginModal() {
props?.closeModal();
// window.location.reload();
}

async function loginSubmit(event) {
event.preventDefault();

const res = await loginWithPhoneNumberApi(loginData);
if (res.success === true) {
// updateData(loginData);
setOtpPageSeen(true);

} else {
setOtpPageSeen(false);
toast.error("Failed to send OTP. Please try again.");
}
}

const countryCodes = [
{ code: "+91", name: "India" },
{ code: "+1", name: "USA" },
{ code: "+44", name: "UK" },
{ code: "+61", name: "Australia" },
{ code: "+81", name: "Japan" },
{ code: "+86", name: "China" },
{ code: "+49", name: "Germany" },
{ code: "+33", name: "France" },
{ code: "+39", name: "Italy" },
{ code: "+7", name: "Russia" },
{ code: "+20", name: "Egypt" },
{ code: "+34", name: "Spain" },
{ code: "+55", name: "Brazil" },
{ code: "+61", name: "Australia" },
{ code: "+66", name: "Thailand" },
{ code: "+27", name: "South Africa" },
{ code: "+52", name: "Mexico" },
{ code: "+47", name: "Norway" },
{ code: "+46", name: "Sweden" },
{ code: "+41", name: "Switzerland" },
{ code: "+31", name: "Netherlands" },
{ code: "+32", name: "Belgium" },
{ code: "+43", name: "Austria" },
{ code: "+48", name: "Poland" },
{ code: "+30", name: "Greece" },
{ code: "+90", name: "Turkey" },
{ code: "+351", name: "Portugal" },
{ code: "+353", name: "Ireland" },
{ code: "+358", name: "Finland" },
{ code: "+36", name: "Hungary" },
{ code: "+420", name: "Czech Republic" },
{ code: "+421", name: "Slovakia" },
{ code: "+386", name: "Slovenia" },
{ code: "+372", name: "Estonia" },
{ code: "+371", name: "Latvia" },
{ code: "+370", name: "Lithuania" },
{ code: "+375", name: "Belarus" },
{ code: "+380", name: "Ukraine" },
{ code: "+40", name: "Romania" },
{ code: "+359", name: "Bulgaria" },
{ code: "+385", name: "Croatia" },
{ code: "+381", name: "Serbia" },
{ code: "+387", name: "Bosnia" },
{ code: "+382", name: "Montenegro" },
{ code: "+383", name: "Kosovo" },
{ code: "+355", name: "Albania" },
{ code: "+389", name: "Macedonia" },
{ code: "+236", name: "Africa" },
{ code: "+243", name: "Congo" },
{ code: "+232", name: "Sierra Leone" },
{ code: "+221", name: "Senegal" },
{ code: "+254", name: "Kenya" },
{ code: "+255", name: "Tanzania" },
{ code: "+256", name: "Uganda" },
{ code: "+260", name: "Zambia" },
{ code: "+263", name: "Zimbabwe" },
{ code: "+234", name: "Nigeria" },
{ code: "+233", name: "Ghana" },
{ code: "+237", name: "Cameroon" },
{ code: "+250", name: "Rwanda" },
{ code: "+212", name: "Morocco" },
{ code: "+216", name: "Tunisia" },
{ code: "+213", name: "Algeria" },
{ code: "+218", name: "Libya" },
{ code: "+251", name: "Ethiopia" },
{ code: "+253", name: "Djibouti" },
{ code: "+252", name: "Somalia" },
{ code: "+260", name: "Zambia" },
{ code: "+266", name: "Lesotho" },
{ code: "+267", name: "Botswana" },
{ code: "+263", name: "Zimbabwe" },
{ code: "+62", name: "Indonesia" },
{ code: "+63", name: "Philippines" },
{ code: "+64", name: "New Zealand" },
{ code: "+65", name: "Singapore" },
{ code: "+673", name: "Brunei" },
{ code: "+60", name: "Malaysia" },
{ code: "+95", name: "Myanmar" },
{ code: "+855", name: "Cambodia" },
{ code: "+856", name: "Laos" },
{ code: "+84", name: "Vietnam" },
{ code: "+92", name: "Pakistan" },
{ code: "+93", name: "Afghanistan" },
{ code: "+94", name: "Sri Lanka" },
{ code: "+880", name: "Bangladesh" },
{ code: "+977", name: "Nepal" },
{ code: "+975", name: "Bhutan" },
{ code: "+960", name: "Maldives" },
{ code: "+976", name: "Mongolia" },
{ code: "+998", name: "Uzbekistan" },
{ code: "+995", name: "Georgia" },
{ code: "+994", name: "Azerbaijan" },
{ code: "+374", name: "Armenia" },
{ code: "+961", name: "Lebanon" },
{ code: "+962", name: "Jordan" },
{ code: "+963", name: "Syria" },
{ code: "+964", name: "Iraq" },
{ code: "+965", name: "Kuwait" },
{ code: "+966", name: "Saudi Arabia" },
{ code: "+968", name: "Oman" },
{ code: "+971", name: "UAE" },
{ code: "+973", name: "Bahrain" },
{ code: "+974", name: "Qatar" },
{ code: "+970", name: "Palestine" }
];


return (
<>
<ToastContainer />
{/*  */}
<div className="loing_modal_maon_div">
<div className="modal fade show" id="login" tabIndex={-1} style={{ display: "block" }}>
<div className="modal-dialog modal-dialog-centered">
<div className="modal-content">
<div className="modal-header">
<h5 className="modal-title"> <i className="fa fa-users " /> Login Here !</h5>
<button type="button" className="fa fa-close" onClick={toggleCloseLoginModal} />
</div>
<div className="modal-body">
{otpPageSeen ? (
<h3>Please Enter the received Verification Code below.</h3>
) : (
<h3>You Will Receive a Code for Verification</h3>
)}
 
<form method="POST">
<label htmlFor="username">Enter Phone No.</label>
<div className="d-flex align-items-center login_modal">
<select className="form-control">
{countryCodes?.map((country, index) => (
<option key={index} value={country.code}>
{
`${country.name}  ${country.code}`
}
</option>
))}
</select>
<input type="text" name="phone" className="form-control" placeholder="Enter Phone No." maxLength={10} pattern="[1-9]{1}[0-9]{9}"
onChange={loginChange}/>
</div>
{!otpPageSeen ? (
<button type="submit" name="getOTP" onClick={loginSubmit} className="text-danger">
Get OTP
</button>
) : (
<>
<label htmlFor="password">OTP</label>
<div className="login_modal">
<input type="password" className="form-control" name="otp" onChange={loginChange} placeholder="Enter OTP" pattern="[1-9]{1}[0-9]{9}"
maxLength={5}/>
</div>

<button type="submit" name="verifyOTP" onClick={otpSubmit} className="text-danger mt-2"> Verify </button>
</>
)}
</form>
<p className="pt-1 m-0">
<span className="pe-1">By Signing up, you agree to our</span>
<a href="https://healfate.com/terms-and-conditions"> Terms of Conditions </a><span className="px-1">and</span> <a href="https://healfate.com/privacy-policy">Privacy Policy</a></p>
</div>
</div>
</div>
</div>
</div>
{/*  */}
</>
);
};

export default Login;
