import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';



// export const  cookieDomain = '.healfate.com';
export const cookieDomain = '';
// ,

//   "proxy": "https://healfate.com/custom_api"
// export const domainName=`https://react.exploretriponline.com/`;
// export const Domain_Url=`https://react.exploretriponline.com/`;

export const domainName = `http://localhost:3000` 

export const Domain_Url=`http://localhost:3000`



// Secret pass key for encryption/decryption
const SECRET_KEY = 'reacttalkndhealhealfate';

export const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  return encrypted;
};

export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decrypted;
};


// Set encrypted data in cookie
export const setEncryptedCookie = (key, data) => {
    const encryptedData = encryptData(data);
    Cookies.set(key, encryptedData, { expires: 7 }); // Cookie expires in 7 days
  };


  // Get and decrypt data from cookie
export const getDecryptedCookie = (key) => {
    const encryptedData = Cookies.get(key);
    if (encryptedData) {
      return decryptData(encryptedData);
    }
    return null;
  };


  export const isAuthenticated=Cookies?.get('token')