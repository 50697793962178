import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Notifications.css';

const Notifications = () => {
  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}
    {/* notification section start */}
      <section className="notification_section">
  <div className="container">
    {/**/}
    <div className="notifiaction_head_ti">
      <h1>Notifications</h1>
    </div>
    {/**/}
    <div className="row justify-content-center">
      <div className="col-lg-7 col-md-12 col-sm-12 col-12">

        {/**/}
        <div className="notification_div_main">
          <h4>Acharya Shashi is online</h4>
          <p>Join before their waitlist grows!</p>
          <div className="date_div_noti">27 June 2024 </div>
        </div>
        {/*  */}

        
        {/**/}
        <div className="notification_div_main">
          <h4>Acharya Shashi is online</h4>
          <p>Join before their waitlist grows!</p>
          <div className="date_div_noti">27 June 2024 </div>
        </div>
        {/*  */}


        
        {/**/}
        <div className="notification_div_main">
          <h4>Acharya Shashi is online</h4>
          <p>Join before their waitlist grows!</p>
          <div className="date_div_noti">27 June 2024 </div>
        </div>
        {/*  */}


        
        {/**/}
        <div className="notification_div_main">
          <h4>Acharya Shashi is online</h4>
          <p>Join before their waitlist grows!</p>
          <div className="date_div_noti">27 June 2024 </div>
        </div>
        {/*  */}


        
        {/**/}
        <div className="notification_div_main">
          <h4>Acharya Shashi is online</h4>
          <p>Join before their waitlist grows!</p>
          <div className="date_div_noti">27 June 2024 </div>
        </div>
        {/*  */}
       
      </div>
    </div>
  </div>
</section>
    {/* notification section end */}

<Footer/>

    </>
  )
}

export default Notifications
