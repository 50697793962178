import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Samuhikpuja.css';

const Samuhikpuja = () => {
  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}
      {/* Samuhikpuja section start */}
      <section className="samuhikpuja_section">
        <div className="container">
            {/* samuhik puja title start */}
            <div className="samuhik_puja_title">
                <h1>Samuhik Puja</h1>
                <p>On our website, you can book and attend Samuhik Pooja ceremonies by selecting the desired pooja event from the available schedule and registering for participation. Join us in this auspicious journey of collective worship and experience the divine blessings and spiritual upliftment that Samuhik Pooja brings</p>
            </div>
            {/* samuhik puja title end */}
            {/* row start */}
            <div className="row">
                {/* col start */}
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <Link to="/Samuhikpuja/:id">
                <div className="samihikpuja_main_div">
                 <div className="samuhik_puja_img_box">
                    <img src="../images/puja01.jpg" alt="" className='img-fluid'/>
                 </div>
                 <h2>Maa Siddhidatri Puja : 9th navratra</h2>
                 <p>According to religious beliefs, Mother S...</p>
                 <h3>₹ 501 /-</h3>
                 {/* samuhik puja flex div start */}
                 <div className="samuhik_puja_flx_div">
                 <b>01 Jul 2024</b>
                 <b>Book Now <span class="fa fa-angle-double-right"></span></b>
                 </div>
                 {/* samuhik puja flex div end */}
                </div>
                </Link>
                </div>
                 {/* col end */}

                  {/* col start */}
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <Link to="">
                <div className="samihikpuja_main_div">
                 <div className="samuhik_puja_img_box">
                    <img src="../images/puja01.jpg" alt="" className='img-fluid'/>
                 </div>
                 <h2>Maa Siddhidatri Puja : 9th navratra</h2>
                 <p>According to religious beliefs, Mother S...</p>
                 <h3>₹ 501 /-</h3>
                 {/* samuhik puja flex div start */}
                 <div className="samuhik_puja_flx_div">
                 <b>01 Jul 2024</b>
                 <b>Book Now <span class="fa fa-angle-double-right"></span></b>
                 </div>
                 {/* samuhik puja flex div end */}
                </div>
                </Link>
                </div>
                 {/* col end */}


                  {/* col start */}
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <Link to="">
                <div className="samihikpuja_main_div">
                 <div className="samuhik_puja_img_box">
                    <img src="../images/puja01.jpg" alt="" className='img-fluid'/>
                 </div>
                 <h2>Maa Siddhidatri Puja : 9th navratra</h2>
                 <p>According to religious beliefs, Mother S...</p>
                 <h3>₹ 501 /-</h3>
                 {/* samuhik puja flex div start */}
                 <div className="samuhik_puja_flx_div">
                 <b>01 Jul 2024</b>
                 <b>Book Now <span class="fa fa-angle-double-right"></span></b>
                 </div>
                 {/* samuhik puja flex div end */}
                </div>
                </Link>
                </div>
                 {/* col end */}

                   {/* col start */}
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <Link to="">
                <div className="samihikpuja_main_div">
                 <div className="samuhik_puja_img_box">
                    <img src="../images/puja01.jpg" alt="" className='img-fluid'/>
                 </div>
                 <h2>Maa Siddhidatri Puja : 9th navratra</h2>
                 <p>According to religious beliefs, Mother S...</p>
                 <h3>₹ 501 /-</h3>
                 {/* samuhik puja flex div start */}
                 <div className="samuhik_puja_flx_div">
                 <b>01 Jul 2024</b>
                 <b>Book Now <span class="fa fa-angle-double-right"></span></b>
                 </div>
                 {/* samuhik puja flex div end */}
                </div>
                </Link>
                </div>
                 {/* col end */}


                   {/* col start */}
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <Link to="">
                <div className="samihikpuja_main_div">
                 <div className="samuhik_puja_img_box">
                    <img src="../images/puja01.jpg" alt="" className='img-fluid'/>
                 </div>
                 <h2>Maa Siddhidatri Puja : 9th navratra</h2>
                 <p>According to religious beliefs, Mother S...</p>
                 <h3>₹ 501 /-</h3>
                 {/* samuhik puja flex div start */}
                 <div className="samuhik_puja_flx_div">
                 <b>01 Jul 2024</b>
                 <b>Book Now <span class="fa fa-angle-double-right"></span></b>
                 </div>
                 {/* samuhik puja flex div end */}
                </div>
                </Link>
                </div>
                 {/* col end */}


                   {/* col start */}
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <Link to="">
                <div className="samihikpuja_main_div">
                 <div className="samuhik_puja_img_box">
                    <img src="../images/puja01.jpg" alt="" className='img-fluid'/>
                 </div>
                 <h2>Maa Siddhidatri Puja : 9th navratra</h2>
                 <p>According to religious beliefs, Mother S...</p>
                 <h3>₹ 501 /-</h3>
                 {/* samuhik puja flex div start */}
                 <div className="samuhik_puja_flx_div">
                 <b>01 Jul 2024</b>
                 <b>Book Now <span class="fa fa-angle-double-right"></span></b>
                 </div>
                 {/* samuhik puja flex div end */}
                </div>
                </Link>
                </div>
                 {/* col end */}
            </div>
            {/* row end */}
        </div>
      </section>
<Footer/>
      {/* Samuhikpuja section end */}
    </>
  )
}

export default Samuhikpuja
