import React from 'react'
import Header from '../components/Header'
import AstrologersSimpleCards from '../components/AstrologersSimpleCards/AstrologersSimpleCards'
import Footer from '../components/Footer'
import '../CssFiles/live.css';

const Live = () => {
  return (
    <>
           {/* ========= */}
  <Header/>
    {/* ====== */}

    <section className="live_section">
        <div className="container">
            {/* title live  */}
            <div className="live_title_div">
                <h1>LIVE ASTROLOGERS</h1>
                <h2>Live Chat with the Best Astrologers</h2>
            </div>
            {/* title live  */}
            {/* row start  */}
            <div className="row justify-content-center">
                {/* col start */}
                <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                    <div className="live_chat_txt_div">
                        <p>Introducing a new way to connect with astrologers: TalknHeal Live! With TalknHeal Live, you can have real-time sessions with astrologers and ask your questions for free. This innovative platform lets you speak face-to-face with astrologers and get your queries answered, offering the best in astrology guidance. TalknHeal Live allows you to consult top astrologers in India on a variety of topics, including marriage, career, love, health, and much more.</p>
                    </div>
                </div>
                {/* col end */}

            </div>
            {/* row end */}
        </div>
    </section>

    <AstrologersSimpleCards/>
      {/* footer componets start */}
      <Footer/>
      {/*footer componets end */}
    </>
  )
}

export default Live

