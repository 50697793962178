import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Samuhikpujadetails.css';

const Samuhikpujadetails = () => {
  const targetDate = '2024-07-01T00:00:00'; // Set your target date/time here

  function calculateTimeLeft() {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTimeLeft = () => {
    return `${timeLeft.days || 0}Day(s) ${timeLeft.hours || 0}h ${timeLeft.minutes || 0}m ${timeLeft.seconds || 0}s`;
  };

  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}
      {/* samuhik puja details section start */}
      <section className="samuhik_puja_section">
        <div className="container">
          {/* samuhik puja title */}
          <div className="samuhik_pujadt_title">
            <h1>Puja Details</h1>
            <hr />
          </div>
          {/* samuhik_pujadt_title end */}
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-5 col-md-6 col-sm-12 col-12">
              <div className="samuhik_puja_dt_img_box">
                <img src="../images/puja01.jpg" alt="" className="img-fluid" />
              </div>
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-7 col-md-6 col-sm-12 col-12">
              <div className="samuhik_puja_dt_txt_main_div">
                <div className="samuhik_puja_dt_title">
                  <h2>Relationship Healing</h2>
                </div>
                {/* flex div */}
                <div className="left_time_div_flx">
                  <h3>Time Left</h3>
                  <h4>{formatTimeLeft()}</h4>
                </div>
                {/* flex div  */}

                  {/* flex div btn book now*/}
                  <div className="booknow_div_flx">
                  <h5><b>Amount:</b> ₹ 501 /-(GST Applicable)</h5>
                  <h6><Link to="">Book Now</Link></h6>
                </div>
                {/* flex div  */}

                {/**/}
<div className="samuhi_social_div">
  <b>Share with:</b>
  <Link to="" className="facebook"> <span className="fab fa-facebook" /> Facebook</Link>
  <Link to="" className="twitter"> <span className="fab fa-x-twitter" /> Twitter</Link>
  <Link to="" className="whatsapp"> <span className="fab fa-whatsapp" /> Whatsapp</Link>
</div>
{/**/}
 </div>
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="samuhikpuja_pandit_txt_div">
                    {/*  */}
                    <div className="astrologer_samuhik_profile_div">
                        <img src="../images/a1.png" alt="" className='img-fluid'/>
                        <h3>Pandit Pushpa ji</h3>
                    </div>
                    {/*  */}
                    <p>Pushpa Ji, a verified tarot reader with 7 years of experience provides straightforward guidance in numerology & psychic readings. She offers simple yet effective solutions for various life challenges including marriage, career & relationships.</p>
                    <h4>How will it happen? </h4>
                    <ul>
                        <li>Book the Pooja</li>
                        <li>Input your Name and Surname/Gotra for Sankalp</li>
                    </ul>
                  
                </div>
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="samuhikpuja_pandit_txt_div">
                    <h4>What are the benefits? </h4>
                    <ul>
                        <li>Nurture Bonds With Empathy, Forgiveness, & Communication</li>
                    </ul>
                    <h5>Why book with Healfate? </h5>
                    <ul>
                        <li>Healfate is India's largest Devotion tech platform with over 15000 top astrologers and Pandits at your service.</li>
                    </ul>
                    <h5>What is the 30 Day Money Back Guarantee? </h5>
                    <ul>
                        <li>Healfate believes in 100% customer satisfaction.</li>
                    </ul>
                </div>
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
      </section>
      {/* samuhik puja details section end */}
<Footer/>
    </>
  );
};

export default Samuhikpujadetails;
