import React, { useState } from 'react';
import MyContext from './MyContext';

// Create a provider component
const MyProvider = ({ children }) => {
  // Data you want to send through the component tree
  const [contextData, setContextData] = useState({

  });

  const [MobileFilter,setMobileFilter]=useState(false)
  const [endAfterReload,setEndAfterReload]=useState(false)

  const [changeStatusData, setChangeStatusData] = useState({});
  const [changeSearch, setChangeSearch] = useState(false);
  const [serviceFooterData, setServiceFooterData] = useState(false);
  console.warn(serviceFooterData)
 
 
  const [astroid, setAstroid] = useState();
  const [userDetails, setUserDetails] = useState();
  const [intakeChange, setIntakeChange] = useState();
  const [tokenChange, setTokenChange] = useState(false);
  const [videoInitiate,setvideoInitiate]=useState(false)
  const [toggleMobileSearch,setToggleMobileSearch]=useState(false)
  const updateMobileSearch =(newData)=>{
    setToggleMobileSearch(newData)
   }
   const updateMobileFilter =(newData)=>{
    setMobileFilter(newData)
   }
   const updateEndAfterReload =(newData)=>{
    setEndAfterReload(newData)
   }
    const updateVideoInitiate= (newData) => {
      setvideoInitiate(newData);
      
    };
 

  const updateData = (newData) => {
    setContextData(newData);
    
  };


  const serviceFooterSend = (newData) => {
    setServiceFooterData(newData);
    
  };


  
  const statusData = (newData) => {
    setChangeStatusData(newData);
  
  };


  const openSearchBarData = (newData) => {
    setChangeSearch(newData);
   
  };


  const singleUserId = (id) => {
    setAstroid(id);
   
  };

  const updateUserDetails = (id) => {
    setUserDetails(id);

  };

  const updateIntake=(id) => {
   
    setIntakeChange(id);

  };

  const updateToken=(id) => {
   
    setTokenChange(id);

  };
  return (
    <MyContext.Provider value={{ contextData, updateData,singleUserId,statusData,MobileFilter,updateMobileFilter, astroid,serviceFooterSend,serviceFooterData,
    updateUserDetails,changeStatusData,userDetails,openSearchBarData,changeSearch ,intakeChange,updateIntake,updateToken,tokenChange,
    updateVideoInitiate,videoInitiate,toggleMobileSearch,updateMobileSearch,endAfterReload,updateEndAfterReload}}>
      {children}
    </MyContext.Provider>
  );
};

export default MyProvider;
