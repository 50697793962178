import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MyContext from '../../Context/MyContext';
import { PostAllGiftApi, getAllGiftApi, loginWithPhoneNumberApi, otpVerifiedApi } from '../../AllApi/AllApi';

import { FaGift, FaWallet } from 'react-icons/fa6';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "js-cookie";
import { decrypt } from '../../Utils/Utils';


const GiftPage = ({ closeModal, id }) => {

  const contextData = useContext(MyContext);
  const [walletAmount, setWalletAmount] = useState(0)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [url, setUrl] = useState();

  useEffect(() => {
    setWalletAmount(contextData?.userDetails?.data?.wallet)
  }, [walletAmount])

  useEffect(() => {
    getAllGifts();
  }, []);

  const [amount, setAmount] = useState(0)
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (index, amount) => {
    if (selectedCard !== index) {
      const g = setSelectedCard(index);
      setAmount(amount);
    }
  };


  const [isDataSent, setIsDataSent] = useState(false);


  async function sendGiftToAstrologer(astid) {
    if (isDataSent) return;

    const userid = decrypt(Cookies?.get('user'))
    if (!userid) {
        toast.error("User ID is required to send a gift.");
        return;
    }

    if (!amount) {
        toast.error("Amount is required to send a gift.");
        return;
    }

    const astroid = astid;
    const resSendData = { userid, astroid, amount };
    const res = await PostAllGiftApi(resSendData);

  

    if (res.success) {
        setIsButtonDisabled(true);
        closeModal();

        toast.success(res.message);
        sessionStorage.removeItem('giftPage')
        setTimeout(() => {
          
            window.location.reload();
        }, 2000);
    } else {
        closeModal();
     
        if (walletAmount <= amount) {
            toast.warn(res.message);
        }
    }
}



  const [allGifts, setAllGifts] = useState([])

console.warn(allGifts)


  async function getAllGifts() {
    const res = await getAllGiftApi()
    if (res.success == true) {
      setUrl(res?.url)
      setAllGifts(res.data)

    }
  }

  useEffect(() => {
    getAllGifts()
  }, [])


  return (
    <>

{/* gift popup start */}
      <div className="gift_poppup_main_div">
      <div className="modal fade show"  tabIndex={-1} style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="staticBackdropLabel">
         {/*  */}
          <div className="gifts_header_title_main">
            {/*  */}
            <div className="gifts_flx_ti">
           <FaGift size={15}/>  <span>Send Gift</span>
            </div>
            {/*  */}

             {/*  */}
             <div className="gifts_flx_ti">
             <FaWallet size={15} /> <span>₹ <b> {walletAmount}/-</b></span> 
            </div>
            {/*  */}
          </div>
         {/*  */}
         </h1>
        <button type="button" className="fa fa-times" onClick={closeModal}></button>
      </div>
        <div className="modal-body">
                  {/*  */}
                 <div className="container">
               <div className="row">
                {/* col start */}
                {allGifts?.map((ele, index) =>{
                  return(
                    <>
       <div className="col-lg-4 col-md-4 col-6" key={index}>
         <div className={`main_div_gifts ${selectedCard === index ? 'activeGiftCard' : ''}`} onClick={() => handleCardClick(index, ele?.amount)}>
          <Link to="">
          <div className="gifts_img_box">
          <img src={`${url}/${ele?.icon}`} alt={ele?.name}  className='img-fluid'/>
          </div>
          <h3 className={selectedCard === index ? 'text-danger' : 'text-black'}>{ele?.name}</h3>
          <h5 className={`price_gifts ${selectedCard === index ? 'text-danger pt-1' : 'pt-1'}`}>₹ {ele?.amount}</h5>
          </Link>
         </div>
        </div>
                    </>
                  )
                })}
       
    {/* col end */}

    {/* btn div start */}
    <div className="col-lg-12">
      <div className="gift_btn_div">
        <Link to="" onClick={() => sendGiftToAstrologer(id)} disabled={isButtonDisabled}>Send</Link>
      </div>
    </div>
    {/* btn div end */}
  </div>
</div>

                  {/*  */}
        </div>
      </div>
    </div>
      </div>
      </div>



{/* gift popup end */}


    </>
  );
};

export default GiftPage;
